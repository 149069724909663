/* eslint-disable autofix/no-unused-vars */
import { environment } from "./environment";

export enum FEATURE_FLAGS {
	//Фича -подключение к  старому бекенду, до их перехода на оцелот и прочее.
	OLD_BACKEND_CONNECTION = "oldBackendConnection",
}
export const FeaturesChecker = {
	check: (feature: FEATURE_FLAGS): boolean =>
		environment.featuresFlags?.some(
			(item: string) => item.toLowerCase() == feature.toLowerCase()
		),
};
