import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { requestType } from "../../../types/system/i-request";
import { environment } from "../../../environment";
import { urlConcat } from "../../../shared/utils/url-concat";
import { RootState } from "../store";

export const feedbackFileApi = createApi({
	reducerPath: "feedbak-file",
	baseQuery: fetchBaseQuery({
		baseUrl: urlConcat(environment.urlGateway.trim(), "/api/"),
		credentials: "include",
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).authManager.userToken;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),

	endpoints: (builder) => ({
		setFeedback: builder.mutation<unknown, FormData>({
			query: (obj) => ({
				url: `Feedback`,
				method: requestType.POST,
				body: obj,
			}),
		}),
	}),
});

export const { useSetFeedbackMutation } = feedbackFileApi;
