import { trainingModelSteps } from "../common/i-status";
//Статус страницы
export enum IPageStatus {
	loading = "loading",
	success = "success",
	error = "error",
	idle = "idle",
}
/* Вкладки создания модели  */
export enum IExpModelTabs {
	constructor = "Конструктор",
	charts = "Графики",
}
/* Информация о всех этапах создания модели  */
export type ITrainingPageStatus = {
	[step in trainingModelSteps]: IPageStatus;
};
/* Типы натификаций */
export type NotificationType = "success" | "info" | "warning" | "error";
