import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IModel } from "../../../types/api/i-api-model";

/* TODO: Переделать в соответствии с новой */
// Менеджер моделей мониторинга
type IMonitoringModels = {
	selectedModelsWithOutputs: IModel[] | null | undefined; //все модели с аутпутсами (для факторного и высокого риска)
};

const INITIAL_STATE_MODELS_MANAGER: IMonitoringModels = {
	selectedModelsWithOutputs: null,
};
const monitoringModelsSlice = createSlice({
	name: "monitoringModels",
	initialState: INITIAL_STATE_MODELS_MANAGER,
	reducers: {
		selectModelsWithOutputs: (
			state,
			action: PayloadAction<IModel[] | null | undefined>
		) => {
			state.selectedModelsWithOutputs = action.payload;
		},
	},
});

export const { selectModelsWithOutputs } = monitoringModelsSlice.actions;

export default monitoringModelsSlice;
