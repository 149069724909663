export type IStatus = {
	status: string;
	count: number;
};
export type IReason = {
	reason: string;
	count: number;
};
/* Этапы создания модели */
export enum trainingModelSteps {
	arcValues = "arcValues",
	training = "training",
	saving = "saving",
	autoLayout = "autoLayout",
}

export enum cardsStatusType {
	HighRisk = "HighRisk",
	HighRiskKPI = "HighRiskKPI",
	OpenedInProgress = "OpenedInProgress",
	Approved = "Approved",
	Closed = "Closed",
	InProgress = "InProgress",
	Monitoring = "Monitoring",
}

export enum modelsStatusType {
	Work = "Work",
	Off = "Off",
	Problems = "Problems",
	Undefined = "Undefined",
	Null = "Null",
}
