import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);
export enum CustomParamSPATypeEnum {
	string = "STRING",
	int = "INT",
	float = "FLOAT",
	boolean = "BOOLEAN",
	date = "DATE",
}
export type CustomParamSPA = {
	name?: string;
	type?: CustomParamSPATypeEnum;
	value: string | number | boolean;
	as_user_id?: boolean;
};

export function sendSPAEvent(
	eventName: string,
	params: CustomParamSPA[] = [],
	typeEvent = "undefined"
) {
	try {
		(window as any).gpnAnalytics.sendEvent &&
			(window as any).gpnAnalytics.sendEvent(3, {
				componentid: eventName,
				constaeventtype: typeEvent || "undefined",
				component: "OTHER",
				customparams: params,
			})();
	} catch (error) {
		// console.log("errorInitSPA", error);
	}
}

/*  Переменные для отслеживания посещения страниц*/
export const OPEN_PAGE_EVENTNAME = "open_page";
export const ClOSE_PAGE_EVENTNAME = "close_page";

export const PAGE_NAME_СUSTOMPARAM = "Название страницы";
export const DURATION_СUSTOMPARAM = "Длительность посещения (мин)";

export enum PageNameEvent {
	monitoring = "monitoring",
	modeling = "modeling",
	eventsList = "eventsList",
	analytics = "analytics",
	economic = "economic",
}

/* Метод на логирования открытия страницы */
export function onPageInit(pageType: PageNameEvent) {
	localStorage.removeItem(pageType);
	localStorage.setItem(pageType, dayjs().valueOf().toString());
	sendSPAEvent(OPEN_PAGE_EVENTNAME, [
		{
			name: PAGE_NAME_СUSTOMPARAM,
			type: CustomParamSPATypeEnum.string,
			value: pageType as string,
		},
	]);
}
/* Метод на логирования открытия страницы */
export function onPageClose(pageType: PageNameEvent) {
	const lastTimeValue = localStorage.getItem(pageType);
	const curTimeSlice = dayjs();
	const lastOpenPage = dayjs(Number(lastTimeValue || 0));

	const intervalMinutes = Math.floor(
		dayjs.duration(curTimeSlice.diff(lastOpenPage)).asMinutes()
	);
	localStorage.removeItem(pageType);

	sendSPAEvent(ClOSE_PAGE_EVENTNAME, [
		{
			name: PAGE_NAME_СUSTOMPARAM,
			type: CustomParamSPATypeEnum.string,
			value: pageType as string,
		},
		{
			name: DURATION_СUSTOMPARAM,
			type: CustomParamSPATypeEnum.int,
			value: intervalMinutes,
		},
	]);
}

/*  */
