/* eslint-disable @typescript-eslint/no-unused-vars */

import { User } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";
import { environment } from "./environment";
import { IAuthManager } from "./core/services/slices/auth-manager";
import jwt_decode from "jwt-decode";

import { roleType } from "./types/api/i-auth";

export function parseAuthInfo(authInfo: User): IAuthManager {
	const jwtInfo: { realm_access: { roles: roleType } } = jwt_decode(
		authInfo?.access_token
	);
	return {
		authenticated: true,
		currentRole: jwtInfo.realm_access.roles || roleType.unauthorized,
		userName: authInfo?.profile.preferred_username || roleType.unauthorized,
		userToken: authInfo?.access_token || roleType.unauthorized,
		sessionId: authInfo?.session_state || roleType.unauthorized,
		userLoggin: authInfo?.profile.email || roleType.unauthorized,
		permissions: [],
	};
}
const onSigninCallback = () => {
	const prevUrl = AUTH_CONFIG.redirect_uri?.replace(/[?].*/, "");
	window.location.replace(prevUrl || "");
};

export const AUTH_CONFIG: AuthProviderProps = {
	authority: `${environment.keyCloakConfig.url}/realms/${environment.keyCloakConfig.realm}`,
	client_id: environment.keyCloakConfig.clientId,
	redirect_uri: location.href,
	onSigninCallback: onSigninCallback,
	automaticSilentRenew: true,
};
