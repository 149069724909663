import { Point } from "./i-factor-analisys-info";
import { periodType } from "./i-period";
import { IModel, IModelTrain, ITagsPayloadInfo } from "../api/i-api-model";

/* Базовая сущность модели во всем проекте  */
export interface IModelInstance extends IModel {
	type: modelType;
	basisPariod?: IDateRange;
	signalDuration: string | null;
	qualityMetric: number | null;
	inputsTags: ITag[];
	equipStatusTags?: ITag[] | null;
	modelStatus: modelStatus;
	config?: IModelConfig;
	trainingData: IRawTimeSeries[];
	resultsData?: IRawTimeSeries[];
	outputsTags?: ITrainingTag[];
	trainingPeriods?: IModelPeriod;
	isCached?: boolean;
	lastSaveTime?: string;
	algorithmName?: string;
	addInfo?: IAddInfo;
}
/* Теги связанные с моделью  */
export type ITrainingTag = {
	name: string; //тег
	props?: ITagProp;
	points: Point[];
	i: number; //индекс тегов, входные 0-900, состояния 900-бесконечно
};
/* Свойство параметра  */
export type ITagProp = {
	[key: string]: number | string | null;
};

/* Конфиг, распарщенный из settings */
export type IModelConfig = {
	method: string;
	model: string;
	url: string;
	funcName: string;
	withBody: boolean;
	checkOutputValidLimit: boolean;
	checkInputValidLimit: boolean;
	canCall: boolean;
	timeout: number;
	delaySec: number;
	repeatIfNoSuccessSec: number;
	repeatCount: number;
	repeatIfNoGoodSec: number;
	repeatPeriodSec: number;
	canSave: boolean;
	calcMetrics: boolean;
	canCallFromWeb: boolean;
	canCreateIncident?: boolean;
	canScheduled: boolean;
	parallelCount: number;
	incidentLogic: string;
	configs: { [key: string]: any };
};

/* Информация о периодах обучения */
export type IModelPeriod = {
	[periodName in periodType]: IDateRange[];
};

export type IDateRange = { begin: string; end: string };
/* Типы моделей по алгоритму обчения  */
export enum modelType {
	LSTM_AE = "LSTM_AutoEncoder",
	LSTM = "LSTM",
	Hotelling_T2 = "Hotelling_T2",
	Deep_AE = "Deep_AE",
	Variational_AE = "Variational_AE",
	Vanilla_AE = "Vanilla_AE",
	Expression = "Expression",
	KIP = "KIP",
	CNN = "CNN",
	OfflineRule = "OfflineRule",
	ModelRule = "ModelRule",
	VirtualRule = "VirtualRule",
	keys = "keys",
}
/* тип кэша */
export enum cacheType {
	Create = "Create",
	Edit = "Edit",
}
/* Тег с учетом границ */
export type ITag = {
	id: number;
	tagName: string;
	min: number | null;
	max: number | null;
	isHidden?: boolean;
	props?: ITagProp;
	name?: string; // русское  название тега (так как модели правил выступают как теги в некоторых ситуациях, приколы бека)
};
/* Периоды состояния моделей */
export type IOptional = {
	[key: string]: IDateRange[] | string;
};

export type ITagSettings = {
	tag: ITagsPayloadInfo;
	isDateExist: boolean;
};

//для сохранения точек результата обучения
export type ITrainResult = {
	tag: string;
	points: Point[];
};

export type IModelState = {
	id: number;
	state: boolean;
};

type modelStatus = {
	isExist: boolean;
	isRetrained: boolean;
	isNeedToRetrain: boolean;
	isNeedToParseOffline: boolean;
};

export type IAddInfo = Map<string, Map<string, number>>;
/* Начальное состояние модели по фронту  */
export type IInitModelAction = {
	modelState: IModelInstance;
	modifyDate?: string;
	changeInfo?: string;
};
//Временные ряды с бека
export type IRawTimeSeries = {
	name: string;
	unit: string;
	points: Point[];
};
/* Временные ряды для отображения на графикакз */
export interface IChartSeries extends IRawTimeSeries {
	color?: string;
	description?: string;
	isHide?: boolean;
	type?: string;
	props?: SeriesProp;
	index?: number;
}

//Модель правил
export type IExpTag = {
	id: number;
	label: string;
	description: string;
};

export enum statisticModelType {
	Online = "Online",
	Offline = "Offline",
	Problems = "Problems",
}
export type UpdateStatisticModelPayload = {
	typeCounter: statisticModelType;
	currentCounter: number;
};
export type IModelDataStatus = {
	[type in IModelDataStatusKind]: number;
};

export type IModelDataStatusKind = "actual" | "predict";
export type IModelStatus = "normal" | "anomaly" | "offline" | "empty";

export enum IAnalysysRawSeriesIndexes {
	STATUS = 0,
	VALUE = 1,
	THRESHOLD = 2,
	EWMA = 3,
	FORECAST_STATUS = 4,
	PRED = 7,
	SHORT_PRED = 8,
	PERFOMANCE = 1000,
	PROB = 1001,
	INTEGRAL_PROB = 1003,
}

export interface IAnalysysRawSeries extends IRawTimeSeries {
	i: number;
	props: any /* Временно */;
	displayName: string;
	index: IAnalysysRawSeriesIndexes;
}

/* Информация о моделях для анализа  */
export type AnalysysDataModel = {
	inputs: IAnalysysRawSeries[];
	outputs: IAnalysysRawSeries[];
	intervals: IModelPeriod;
};

export type SeriesProp = { [key: string]: string | number };
/* Окончания тегов для фильтации */
export enum TagEndings {
	perfomance = "PERFOMANCE",
	status = "STATUS",
	integral = "INTEGRAL",
	prob = "PROB",
	forecastStatus = "FORECAST_STATUS",
	pred = "PRED",
}

/* Тело запроса на авторазметку  */
export type QueryAutoLayoutBody = {
	autoLayoutAlgorithm: string; //название алгоритма
	sessionId: string; //
	cacheType: cacheType;
	modelBody: IModelTrain; //тело модели;
};
