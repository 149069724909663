import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITreeObject } from "../../../types/api/i-object";
// Менеджер объектов мониторинга
type IMonitoringObjects = {
	selectedObjects: ITreeObject[] | null | undefined; //список всех выбранных объектов в данный момент
	selectedPlant: ITreeObject | null | undefined; //установка выбранная в данный момент
	selectedFactory: ITreeObject | null | undefined; //завод выбранный в данный момент
	selectedProd: ITreeObject | null | undefined; //производство выбранное в данный момент
	selectedEquip: ITreeObject | null | undefined; //оборудование выбранное в данный момент
	selectedObject: ITreeObject | null | undefined; //выбранный объект в данный момент последнего уровня (на котором фокус)
	selectedMonitoredObjects: ITreeObject[] | null | undefined; //список все объектов, выбранных в данный момент
};

const INITIAL_STATE_OBJECTS_MANAGER: IMonitoringObjects = {
	selectedObjects: null,
	selectedPlant: null,
	selectedFactory: null,
	selectedProd: null,
	selectedEquip: null,
	selectedObject: null,
	selectedMonitoredObjects: null,
};
const monitoringObjectsSlice = createSlice({
	name: "monitoringObjects",
	initialState: INITIAL_STATE_OBJECTS_MANAGER,
	reducers: {
		selectObjects: (
			state,
			action: PayloadAction<ITreeObject[] | null | undefined>
		) => {
			state.selectedObjects = action.payload;
		},
		selectProd: (
			state,
			action: PayloadAction<ITreeObject | null | undefined>
		) => {
			state.selectedProd = action.payload;
			if (action.payload) {
				state.selectedObject = action.payload;
			}
			const curSelectedFactory = state.selectedFactory;
			const curSelectedProd = state.selectedProd;
			if (
				!action.payload &&
				!(curSelectedFactory?.children || []).some(
					(item) => item.id == curSelectedProd?.id
				)
			) {
				const curSelectedFactory = state.selectedFactory;
				state.selectedObject = curSelectedFactory;
			}
		},
		selectEquip: (
			state,
			action: PayloadAction<ITreeObject | null | undefined>
		) => {
			state.selectedEquip = action.payload;
			if (action.payload) {
				state.selectedObject = action.payload;
			}
		},
		selectPlant: (
			state,
			action: PayloadAction<ITreeObject | null | undefined>
		) => {
			state.selectedPlant = action.payload;
			if (action.payload) {
				state.selectedObject = action.payload;
			} else {
				const curSelectedProd = state.selectedProd;
				if (curSelectedProd) {
					state.selectedObject = curSelectedProd;
				} else {
					const curSelectedFactory = state.selectedFactory;
					if (curSelectedFactory) {
						state.selectedObject = curSelectedFactory;
					} else {
						state.selectedObject = null;
					}
				}
			}
		},

		selectObject: (
			state,
			action: PayloadAction<ITreeObject | null | undefined>
		) => {
			if (state.selectedObject?.id == action.payload?.id) return;
			state.selectedObject = action.payload;
			//Метод на обновление всех попутных вышестоящих компонентов
			const selectByIdType = (obj: ITreeObject) => {
				switch (obj.idType) {
					case 1:
						state.selectedFactory = obj;
						break;
					case 2:
						state.selectedProd = obj;
						break;
					case 3:
						state.selectedPlant = obj;
						break;
					case 5:
						state.selectedEquip = obj;
						break;
				}
				if (!obj.parent) return;
				selectByIdType(obj.parent);
			};
			const clearChildComps = (obj: ITreeObject) => {
				if (obj.idType == 3) {
					state.selectedEquip = null;
				}
				if (obj.idType == 2) {
					state.selectedPlant = null;
					state.selectedEquip = null;
				}
				if (obj.idType == 1) {
					state.selectedProd = null;
					state.selectedPlant = null;
					state.selectedEquip = null;
				}
			};
			if (action.payload) {
				selectByIdType(action.payload);
				clearChildComps(action.payload);
			}
			if (!action.payload) {
				state.selectedFactory = null;
				state.selectedProd = null;
				state.selectedPlant = null;
				state.selectedEquip = null;
			}
		},
		selectMonitoredObjects: (
			state,
			action: PayloadAction<ITreeObject[] | null | undefined>
		) => {
			state.selectedMonitoredObjects = action.payload;
		},
	},
});

export const {
	selectObjects,
	selectPlant,
	selectProd,
	selectEquip,
	selectObject,
	selectMonitoredObjects,
} = monitoringObjectsSlice.actions;

export default monitoringObjectsSlice;
