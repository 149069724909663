import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../../../environment";
import { urlConcat } from "../../../shared/utils/url-concat";
import { RootState } from "../store";
import {
	IOperationalPayload,
	IOperationalStatisticBody,
} from "../../../types/api/i-statistics";

export const dataSeriesApi = createApi({
	reducerPath: "data-series",
	baseQuery: fetchBaseQuery({
		baseUrl: urlConcat(environment.urlGateway.trim(), "/api/"),
		credentials: "include",
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).authManager.userToken;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		/* Вытягиваю все объекты */
		getOperationalById: builder.query<
			IOperationalStatisticBody,
			IOperationalPayload
		>({
			query: (contract: IOperationalPayload) => ({
				url: `Incidents/operational`,
				params: {
					objective: contract.id,
					from: contract.dateStart,
					to: contract.dateEnd,
				},
			}),
		}),
	}),
});

export const { useLazyGetOperationalByIdQuery } = dataSeriesApi;
