import * as signalR from "@microsoft/signalr";
import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../../../environment";
import { requestType } from "../../../types/system/i-request";

import FileSaver from "file-saver";
import { urlConcat } from "../../../shared/utils/url-concat";
import {
	GetCardsByPeriodQueryParams,
	ICard,
	IncidentEvent,
} from "../../../types/api/i-card";
import {
	IKPIReasons,
	IKPIStatisticRange,
	IKPIStatistics,
	IReasons,
	IStatusStep,
} from "../../../types/api/i-statistics";
import { RootState } from "../store";

import { FEATURE_FLAGS, FeaturesChecker } from "../../../feature-flags-checker";
import { getFileName } from "../../../shared/utils/file-operations";

const cardsReportUrl = FeaturesChecker.check(
	FEATURE_FLAGS.OLD_BACKEND_CONNECTION
)
	? "api/Reports/cards"
	: `${environment.urlReportService}/api/Reports/cards`;

const cardsAdapter = createEntityAdapter<ICard>();

export const CardLibraryApi = createApi({
	reducerPath: "card-library",

	baseQuery: fetchBaseQuery({
		baseUrl: environment.urlGateway.trim(),
		credentials: "include",
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).authManager.userToken;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ["Cards"],

	endpoints: (builder) => ({
		/* Получаю все карточки */
		getAllCards: builder.query<EntityState<ICard>, void>({
			query: () => ({
				url: "api/Incidents/cards",
			}),
			transformResponse(response: ICard[]) {
				return cardsAdapter.addMany(cardsAdapter.getInitialState(), response);
			},
			providesTags: ["Cards"],
			async onCacheEntryAdded(
				arg,
				{ updateCachedData, cacheDataLoaded, cacheEntryRemoved }
			): Promise<void> {
				const hubConnection = new signalR.HubConnectionBuilder()
					.withUrl(urlConcat(environment.urlGateway.trim(), "/eventhub"))
					.withAutomaticReconnect()
					.build();
				try {
					await cacheDataLoaded;
					hubConnection
						.start()
						.then(() => console.log("Connection started"))
						.catch((e) => {
							console.log("Error", e.MassageUps);
						})
						.then(() =>
							hubConnection.invoke("Identifier", b64EncodeUnicode("Ryabko.DA"))
						);

					hubConnection.on(
						"SubscribeEvents",
						async (keyMap: any, key: any, message: any) => {
							const newCard: ICard = {
								...(message.payload as ICard),
								isNew: true,
							};
							updateCachedData((cards) => {
								const oldArr = cardsAdapter.getSelectors().selectAll(cards);
								const newArr: ICard[] = [];

								oldArr.forEach((item) => {
									const updCard = { ...item };
									try {
										if (updCard?.isNew) updCard.isNew = false;
									} catch (error) {
										console.log("Error", error);
									}
									newArr.push(updCard);
								});
								cardsAdapter.setAll(cards, newArr);
								cardsAdapter.upsertOne(cards, newCard);
							});
						}
					);
				} catch (error) {
					console.log("Error", error);
				}
				await cacheEntryRemoved;
				// perform cleanup steps once the `cacheEntryRemoved` promise resolves
				hubConnection.stop();
			},
		}),
		getAllCardsByPeriod: builder.query<ICard[], GetCardsByPeriodQueryParams>({
			query: (payload) => ({
				url: `${environment.urlEventCollector}/api/Cards/interval`,
				params: payload,
			}),
		}),
		getCardsReport: builder.query<
			Blob,
			Omit<GetCardsByPeriodQueryParams, "objective">
		>({
			query: (payload) => ({
				url: cardsReportUrl,
				params: payload,
				responseHandler: async (response) => {
					const file = await response.blob();
					const name = getFileName(response.headers);
					FileSaver.saveAs(file, name);
				},
			}),
		}),
		getAllStatusesStatistic: builder.query<ICard[], void>({
			query: () => ({
				url: "api/Incidents/statistics/statuses",
			}),
		}),
		/*  */
		getAllStatusesSteps: builder.query<IStatusStep[], void>({
			query: () => ({
				url: "api/Incidents/statuses",
			}),
		}),
		/* Получаю все причины */
		getAllReasons: builder.query<IReasons[], void>({
			query: () => ({
				url: `api/Incidents/reasons`,
			}),
		}),
		getAllStatisticsReasons: builder.query<IKPIReasons[], IKPIStatisticRange>({
			query: (obj: IKPIStatisticRange) => ({
				url: `api/Incidents/statistics/reasons?objective=${obj.id}&from=${obj.from}&to=${obj.to}`,
			}),
		}),
		getAllStatisticsStatuses: builder.query<IKPIStatistics[], IKPIStatisticRange>(
			{
				query: (obj: IKPIStatisticRange) => ({
					url: `api/Incidents/statistics/statuses?objective=${obj.id}&from=${obj.from}&to=${obj.to}`,
				}),
			}
		),
		updateCard: builder.mutation<null, IncidentEvent>({
			query: (card: IncidentEvent) => ({
				url: `api/Incidents/update/card`,
				method: requestType.PUT,
				body: card,
			}),
			invalidatesTags: ["Cards"],
		}),
	}),
});

export const {
	useGetAllCardsQuery,
	useLazyGetAllCardsByPeriodQuery,
	useLazyGetCardsReportQuery,
	useLazyGetAllStatusesStatisticQuery,
	useGetAllReasonsQuery,
	useGetAllStatisticsReasonsQuery,
	useLazyGetAllStatisticsReasonsQuery,
	useLazyGetAllStatisticsStatusesQuery,
	useUpdateCardMutation,
	useGetAllStatusesStatisticQuery,
	useGetAllStatusesStepsQuery,
} = CardLibraryApi;

export function getLastFileCardLibrary() {
	window.open(
		urlConcat(environment.urlGateway.trim(), "/api/") +
			"CardProperties/last/file",
		"_blank"
	);
}

// Изменение кодировки на Base64
function b64EncodeUnicode(str: string): string {
	if (window && "btoa" in window && "encodeURIComponent" in window) {
		return btoa(
			encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
				String.fromCharCode(("0x" + p1) as any)
			)
		);
	} else {
		console.warn(
			"b64EncodeUnicode requirements: window.btoa and window.encodeURIComponent functions"
		);
		return "null";
	}
}
