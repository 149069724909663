import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	ThemeType,
	AVIABLE_THEME_CONFIGS,
} from "../../../styles/themes-config";

export interface IUIState {
	aviablesThemes: ThemeType[];
	currentTheme: ThemeType;
}
const INITIAL_STATE_UI_MANAGER: IUIState = {
	aviablesThemes: AVIABLE_THEME_CONFIGS,
	currentTheme: AVIABLE_THEME_CONFIGS[0],
};

const interfaceManagerSlice = createSlice({
	name: "interfaceManager",
	initialState: INITIAL_STATE_UI_MANAGER,
	reducers: {
		setTheme: (state, action: PayloadAction<ThemeType>) => {
			if (state.currentTheme.label == action.payload.label) return;
			state.currentTheme = action.payload;
		},
	},
});

export const { setTheme } = interfaceManagerSlice.actions;

export default interfaceManagerSlice;
