import dayjs from "dayjs";
import { IAlgorithm, IModelConfigs } from "../../types/api/i-api-model";
import {
	IExpTag,
	IModelInstance,
	ITag,
	ITagSettings,
	ITrainingTag,
} from "../../types/common/i-model";
import { ITagInputs } from "../../types/api/i-tag";
import { IChartData } from "../../types/system/i-chart";
import { IItem } from "../../types/system/i-item";
import { IPeriod, periodType } from "../../types/common/i-period";
import { ISOJSONDateFormat } from "./time-formats";
export type IAllTagsInfo = {
	tagsAll: string[];
	equipTags: ITag[];
	inTags: ITag[];
};
export function setOldPeriods(modelConfigs: any) {
	let periodId = 0;
	const pusedOldPeriods: IPeriod[] = []; //спаршенные периоды
	if (modelConfigs.Normal) {
		for (const item of modelConfigs.Normal) {
			//забираем разметку с периодом
			pusedOldPeriods.push(peperePeriod(item, periodId, periodType.Normal));
			periodId++;
		}
	}
	if (modelConfigs.Failurs) {
		for (const item of modelConfigs.Failurs) {
			pusedOldPeriods.push(peperePeriod(item, periodId, periodType.Failurs));
			periodId++;
		}
	}
	if (modelConfigs.Anomaly) {
		for (const item of modelConfigs.Anomaly) {
			pusedOldPeriods.push(peperePeriod(item, periodId, periodType.Anomaly));
			periodId++;
		}
	}
	if (modelConfigs.AnomalyManual) {
		for (const item of modelConfigs.AnomalyManual) {
			pusedOldPeriods.push(peperePeriod(item, periodId, periodType.AnomalyManual));
			periodId++;
		}
	}
	if (modelConfigs.Offline) {
		for (const item of modelConfigs.Offline) {
			pusedOldPeriods.push(peperePeriod(item, periodId, periodType.Offline));
			periodId++;
		}
	}
	return pusedOldPeriods;
}
function peperePeriod(item: any, periodId: number, type: periodType) {
	const pushedPeriod: IPeriod = {
		type: type,
		dateStart: dayjs(item.begin).format(ISOJSONDateFormat),
		dateEnd: dayjs(item.end).format(ISOJSONDateFormat),
		id: periodId,
	};
	return pushedPeriod;
}

export function genTagsInfo(tagsData: ITagInputs[]) {
	const tagsInfo: IAllTagsInfo = {
		tagsAll: [],
		equipTags: [],
		inTags: [],
	};
	for (const item of tagsData) {
		if (item.type == 1 && item.index != 999) {
			const tagProps = JSON.parse(item.props);
			const pushedTag: ITag = {
				id: item.index,
				tagName: item.parameter.tag,
				min: tagProps.L || 0,
				max: tagProps.H || 0,
				props: tagProps,
			};
			/* В соответствии с документацией эти индексы соответствуют индексам тегов соответсвтия  */
			if (item.index >= 900 && item.index < 999) {
				tagsInfo.equipTags.push(pushedTag);
			} else if (item.index < 900) {
				tagsInfo.inTags.push(pushedTag);
			}
			tagsInfo.tagsAll.push(item.parameter.tag);
		}
	}
	return tagsInfo;
}

export function genTagsSettings(
	tagsInformation: IAllTagsInfo,
	periodExist: boolean,
	sessionId: string,
	periodStart: string,
	periodEnd: string
) {
	const nowDate = new Date();
	const endDate = new Date();
	endDate.setMonth(nowDate.getMonth() - 3);
	if (periodExist) {
		//если в настройках приходит период общий
		const sendingTags: ITagSettings = {
			tag: {
				tags: tagsInformation.tagsAll,
				type: "raw",
				sessionId: sessionId,
				dateStart: periodStart,
				dateEnd: periodEnd,
			},
			isDateExist: true,
		};
		return sendingTags;
	}
	//если в настройках не приходит период общий
	const sendingTags: ITagSettings = {
		tag: {
			tags: tagsInformation.tagsAll,
			type: "raw",
			sessionId: sessionId,
			dateStart: dayjs(nowDate).format(ISOJSONDateFormat),
			dateEnd: dayjs(endDate).format(ISOJSONDateFormat),
		},
		isDateExist: false,
	};
	return sendingTags;
}

export function dataForChartGen(items: any) {
	const data: IChartData[] = [];
	for (const item of items) {
		if (item.points) {
			const pushedParameter: IChartData = {
				type: item.name,
				tag: item.name,
				points: item.points,
				unit: item.unit,
			};
			data.push(pushedParameter);
		}
	}
	return data;
}

export function setAlgorithms(algs: IAlgorithm[], algorithm: any) {
	const newAlgs: IAlgorithm[] = [];
	for (const item of algs) {
		const pushedAlg: IAlgorithm = {
			id: item.id,
			name: item.name,
			description: item.description,
			idContract: item.idContract,
			idExecutor: item.idExecutor,
			consts: item.consts,
			paramProps: item.paramProps,
			funcs: item.funcs,
		};
		newAlgs.push(pushedAlg);
	}
	const newModelTypes: IItem[] = [];
	for (const item of newAlgs) {
		const pushedType: IItem = {
			label: item.name,
			id: item.id,
		};
		newModelTypes.push(pushedType);
	}
	const alg = newModelTypes.find((item) => item.label == algorithm);
	return alg;
}

export function parseTagsFromExpression(expression?: string): string[] {
	if (!expression) {
		return [];
	}
	const regex = /\[(.*?)\]/g;
	const matches = expression.match(regex);

	if (!matches) {
		return [];
	}

	const uniqueTags: Set<string> = new Set(
		matches.map((match) => match.slice(1, -1))
	);
	return Array.from(uniqueTags);
}

export function parseToExprTag(tags: ITag[]): IExpTag[] {
	const expTags: IExpTag[] = tags.map((tag) => ({
		id: tag.id,
		label: tag.tagName,
		description: "",
	}));
	return expTags;
}

export function parseCreatedToExprTag(tags: ITrainingTag[]): IExpTag[] {
	const expTags: IExpTag[] = tags.map((tag, index) => ({
		id: index,
		label: tag.name,
		description: "",
	}));
	return expTags;
}

export function parseExpressionFromConfig(
	model: IModelInstance,
	data: IModelConfigs
): IModelInstance {
	const consts = data?.consts;

	let parsedConsts = null;

	if (!consts) return model;

	try {
		parsedConsts = JSON.parse(consts);

		if (!parsedConsts.expression) return model;

		return {
			...model,
			consts: parsedConsts,
		};
	} catch (err) {
		console.error("Произошла ошибка при парсинге поля 'consts'");
	}

	return model;
}
