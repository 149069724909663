import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICard } from "../../../types/api/i-card";

type EconomicEffectSliceState = {
	allCards: ICard[];
	selectedCard: ICard | null;
};

const initialState: EconomicEffectSliceState = {
	allCards: [],
	selectedCard: null,
};

const economicEffectSlice = createSlice({
	name: "economicEffectSlice",
	initialState,
	reducers: {
		initAllCards: (state, action: PayloadAction<ICard[]>) => {
			state.allCards = action.payload;
		},
		selectCardId: (state, action: PayloadAction<number>) => {
			const selectedCard = state.allCards.find(
				(item) => item.id === action.payload
			);
			state.selectedCard = selectedCard ? selectedCard : null;
		},
	},
});

export const { initAllCards, selectCardId } = economicEffectSlice.actions;

export default economicEffectSlice;
