/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { analysisDataApi } from "../API/api-analysis-module-redux";

import {
	AnalysysDataModel,
	IAnalysysRawSeriesIndexes,
	IModelPeriod,
	ITrainingTag,
} from "../../../types/common/i-model";
import {
	getModelSetting,
	getTrainingDateRangesModel,
	updateAnomalyPeriodFromTag,
} from "../../../shared/utils/models-operations";
import { cloneDeep } from "lodash";

import { setLocalTime } from "../../../shared/utils/time-formats";
import dayjs from "dayjs";
import { IModel } from "../../../types/api/i-api-model";
import { ILoading } from "../../../types/system/i-loading";
import { DataAggergatedResult } from "../../../types/api/i-data";
import { ChartType } from "../../../types/system/i-chart";
import { AnalysysSettingsType } from "../../../modules/models-analysys-module/types/i-analysys-settings";
import {
	TimeConfig,
	RefreshingConfig,
} from "../../../types/common/i-timer-config";

type ModelAnalysysState = {
	analysData: AnalysysDataModel | null;
	analysysSettings: AnalysysSettingsType | null; //пока string, потом продумаю (информация о том за какой промежуток отдавать и как обновлять данные)
	analysedModel?: IModel | null; //все с запроса на outputsById (какой то IModelInstance, который получаешь из outputsById)
	statusAnalysys: ILoading;
};

const initialState: ModelAnalysysState = {
	analysData: null,
	analysysSettings: null,
	analysedModel: null,
	statusAnalysys: ILoading.idle,
};

/* Готовится к удалению */
export type IGetModelQueryParams = {
	modelName: string;
	startDate: string;
	endDate: string;
};

const modelAnalysSlice = createSlice({
	name: "modelAnalysSlice",
	initialState,
	reducers: {
		selectAnalysedModel: (
			state,
			action: PayloadAction<IModel | null | undefined>
		) => {
			state.analysedModel = action.payload;
			if (!action.payload) return;
			const modelSettings = getModelSetting(action.payload);
			const dataRanges = getTrainingDateRangesModel(modelSettings);
			state.analysData = { inputs: [], outputs: [], intervals: dataRanges };
		},
		changeTimeConfig: (state, action: PayloadAction<TimeConfig>) => {
			if (!action.payload) return;
			state.analysysSettings = {
				...(state.analysysSettings || {}),
				timeConfig: action.payload,
			};
		},
		changeRefreshingConfig: (state, action: PayloadAction<RefreshingConfig>) => {
			if (!action.payload) return;
			state.analysysSettings = {
				...(state.analysysSettings || {}),
				refreshingConfig: action.payload,
			};
		},
		changeStatusAnalysys: (state, action: PayloadAction<ILoading>) => {
			if (!action.payload) return;

			state.statusAnalysys = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			analysisDataApi.endpoints.getAnalysysModelData.matchRejected,
			(state) => ({
				...state,
				statusAnalysys: ILoading.failed,
				analysData: null,
			})
		);
		builder.addMatcher(
			analysisDataApi.endpoints.getAnalysysModelData.matchFulfilled,
			(state, action) => ({
				...state,
				statusAnalysys: ILoading.succeeded,
				analysData: {
					...action.payload,
					intervals: updateIntervalsAfterLoadData(
						cloneDeep(state.analysData?.intervals as IModelPeriod),
						cloneDeep(action.payload)
					),
				},
			})
		);
		builder.addMatcher(
			analysisDataApi.endpoints.getAnalysysModelData.matchPending,
			(state) => ({
				...state,
				statusAnalysys: ILoading.pending,
			})
		);
		builder.addMatcher(
			analysisDataApi.endpoints.getAnalysysModelData.matchRejected,
			(state) => ({
				...state,
				analysData: {
					inputs: [],
					outputs: [],
					intervals: {} as IModelPeriod,
				},
				statusAnalysys: ILoading.failed,
			})
		);
	},
});
export const {
	selectAnalysedModel,
	changeTimeConfig,
	changeRefreshingConfig,
	changeStatusAnalysys,
} = modelAnalysSlice.actions;

export default modelAnalysSlice;

/* Обновление интервалов после получения точек */
function updateIntervalsAfterLoadData(
	intervals: IModelPeriod,
	data: DataAggergatedResult
): IModelPeriod {
	let newIntervals = { ...intervals };
	/* Аномальные интервалы для факта */
	const anomalySeries: ITrainingTag | undefined = data.outputs
		.filter((item) => item.index == IAnalysysRawSeriesIndexes.STATUS)
		.map((item) => ({
			name: item.name,
			points: item.points.map((point) => ({ ...point, d: setLocalTime(point.d) })),
			i: item.index,
		}))
		.at(-1);
	/* Аномальные интервалы для прогноза */
	const lastUpdateDate = anomalySeries?.points?.at(-1)?.d;
	const anomalyPredictSeries: ITrainingTag | undefined = data.outputs
		.filter((item) => item.index == IAnalysysRawSeriesIndexes.FORECAST_STATUS)
		.map((item) => ({
			name: item.name,
			points: item.points
				.filter((item) =>
					!lastUpdateDate ? true : dayjs(lastUpdateDate).isBefore(item.d)
				)
				.map((point) => ({ ...point, d: setLocalTime(point.d) })),
			i: item.index,
		}))
		.at(-1);
	const basisSeries = anomalySeries || anomalyPredictSeries;
	if (!basisSeries) return newIntervals;
	if (anomalyPredictSeries)
		basisSeries.points = basisSeries.points?.concat(
			anomalyPredictSeries?.points || []
		);
	newIntervals = updateAnomalyPeriodFromTag(
		newIntervals,
		basisSeries,
		ChartType.analysis
	);
	/* Аномальные интервалы для прогноза */
	return newIntervals;
}
