import { IItem } from "../../../types/system/i-item";

export const REASONS_LIST: IItem[] = [
	{
		label: "Оповещения не приходят на почту",
		id: 1,
	},
	{
		label: "Данные не поступают в систему",
		id: 2,
	},
	{
		label: "Модели не рассчитываются",
		id: 3,
	},
	{
		label: "Статус модели не изменяется",
		id: 4,
	},
	{
		label: "Проблема с добавлением/сохранением новой модели",
		id: 5,
	},
	{
		label: "Проблема с добавлением/редактированием нового оборудования",
		id: 6,
	},
	{
		label: "Проблема с закрытием/подтверждением оповещений",
		id: 7,
	},
	{
		label: "Не отображаются тренды технологических параметров (grafana)",
		id: 8,
	},
	{
		label: "Не вводится информация по карточкам",
		id: 9,
	},
	{
		label: "Не открываются карточки",
		id: 10,
	},
	{
		label: "Отсутствует или некорректная единица измерения / описание параметра",
		id: 11,
	},
	{
		label: "Другое",
		id: 12,
	},
];
