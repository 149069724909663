import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import { environment } from "../../../environment";
import { urlConcat } from "../../../shared/utils/url-concat";

import { IArcValues, IModelTrainSend } from "../../../types/api/i-api-model";
import { ITagInfo, ITagSend, IEditTagMutation } from "../../../types/api/i-tag";
import {
	IModelPeriod,
	IRawTimeSeries,
	QueryAutoLayoutBody,
} from "../../../types/common/i-model";
import { setLocalTime } from "../../../shared/utils/time-formats";
import { localizeDatePeriod } from "../../../shared/utils/models-operations";
import { FeaturesChecker, FEATURE_FLAGS } from "../../../feature-flags-checker";
import { requestType } from "../../../types/system/i-request";
import { periodType } from "../../../types/common/i-period";

const isOldBackend = FeaturesChecker.check(
	FEATURE_FLAGS.OLD_BACKEND_CONNECTION
);

const prefixUrl = isOldBackend ? "Models" : "Modeling";
const prefixKIPUrl = isOldBackend ? "Models/kip" : "KIP";
const prefixNSIUrl = isOldBackend ? "NSI" : "KIP";
const dataUrl = isOldBackend ? "DataSource" : "Data";

const envUrl = isOldBackend ? environment.urlGateway : environment.urlModeling;

export const modelingApi = createApi({
	reducerPath: "modeling",
	baseQuery: fetchBaseQuery({
		baseUrl: urlConcat(envUrl.trim(), "/api/"),
		credentials: "include",
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).authManager.userToken;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ["Tags", "Models"],
	endpoints: (builder) => ({
		/* Информация по тегам */
		getParameterInputs: builder.query<ITagInfo[], string>({
			query: (objectId: string, count = 5000) => ({
				url: `${prefixNSIUrl}/ParameterInputs`,
				params: { idObject: objectId, count: count },
			}),
			transformResponse(baseQueryReturnValue: ITagInfo[]) {
				return baseQueryReturnValue.sort((a, b) => Number(b.id) - Number(a.id));
			},
			providesTags: (result) =>
				result
					? [...result.map(({ id }) => ({ type: "Tags" as const, id: Number(id) }))]
					: ["Tags"],
		}),
		/* Создание тега */
		saveNewTag: builder.mutation<any, ITagSend>({
			query: (obj) => ({
				url: prefixKIPUrl,
				method: requestType.POST,
				body: obj,
			}),
			invalidatesTags: ["Tags"],
		}),
		/* Редактирование тега */
		getExistedSaveTag: builder.mutation<any, IEditTagMutation>({
			query: ({ payload, isModelExist }) => ({
				url: prefixKIPUrl,
				method: isModelExist ? requestType.PUT : requestType.POST,
				body: payload,
			}),
			invalidatesTags: (result, error, arg) => [{ type: "Tags", id: arg.id }],
		}),
		tagDelete: builder.mutation<any, IEditTagMutation>({
			//удаление тега
			query: ({ payload }) => ({
				url: prefixUrl,
				params: { name: payload.translitModel },
				method: requestType.DELETE,
			}),
			invalidatesTags: (result, error, arg) => [{ type: "Tags", id: arg.id }],
		}),
		arcValuesByTag: builder.mutation<IRawTimeSeries[], IArcValues>({
			query: (obj: IArcValues) => ({
				url: `${dataUrl}/ArcPrepareValuesByTags/${obj.object}`,
				params: {
					eventType: obj.cacheType,
					sessionId: obj.data.sessionId,
				},
				method: requestType.POST,
				body: {
					...obj.data,
					tags: [...new Set(obj.data.tags)]
						.filter((item) => item?.length > 0)
						.map((item) => item.trim()),
				},
			}),
			transformResponse(baseQueryReturnValue: IRawTimeSeries[]) {
				baseQueryReturnValue.forEach((tag) => {
					tag.points.forEach((point) => {
						point.d = setLocalTime(point.d);
					});
				});
				return baseQueryReturnValue;
			},
		}),
		getTrain: builder.mutation<IModelTrainSend, IModelTrainSend>({
			query: (obj) => ({
				url: `${prefixUrl}/training`,
				params: {
					sessionId: obj.sessionId,
					eventType: obj.cacheType,
				},
				method: requestType.POST,
				body: obj,
			}),
			transformResponse(baseQueryReturnValue: IModelTrainSend) {
				baseQueryReturnValue.from = setLocalTime(baseQueryReturnValue.from);
				baseQueryReturnValue.to = setLocalTime(baseQueryReturnValue.to);
				baseQueryReturnValue.inputs.map((tag) => {
					if (tag.points) {
						tag.points.map((point) => {
							point.d = setLocalTime(point.d);
						});
					}
				});
				baseQueryReturnValue.outputs?.map((tag) => {
					if (tag.points) {
						tag.points.map((point) => {
							point.d = setLocalTime(point.d);
						});
					}
				});
				return baseQueryReturnValue;
			},
		}),
		getSaveModel: builder.mutation<unknown, IModelTrainSend>({
			query: (obj) => ({
				url: `${prefixUrl}/withIOConfigs`,
				method: requestType.POST,
				body: obj,
			}),
			invalidatesTags: (result, error, arg) => [
				{ type: "Models", id: arg.modelId },
			],
		}),
		setSaveExistModel: builder.mutation<unknown, IModelTrainSend>({
			//для сохранения уже существующей модели
			query: (obj) => ({
				url: `${prefixUrl}/withIOConfigs`,
				method: requestType.PUT,
				body: obj,
			}),
			invalidatesTags: (result, error, arg) => [
				{ type: "Models", id: arg.modelId },
			],
		}),
		setWithoutTrain: builder.mutation<number, IModelTrainSend>({
			//для сохранения уже существующей модели без переобучения
			query: (obj) => ({
				url: `${prefixUrl}/withoutTrain`,
				method: requestType.PUT,
				body: obj,
			}),
			invalidatesTags: (result, error, arg) => [
				{ type: "Models", id: arg.modelId },
			],
		}),
		modelDelete: builder.mutation<any, { name: string; id: number }>({
			query: (obj) => ({
				url: prefixUrl,
				params: { name: obj.name },
				method: requestType.DELETE,
			}),
			invalidatesTags: (result, error, arg) => [{ type: "Models", id: arg.id }],
		}),
		getSimulate: builder.mutation<IModelTrainSend, IModelTrainSend>({
			query: (model) => ({
				url: `${prefixUrl}/simulate`,
				params: {
					sessionId: model.sessionId,

					modelName: model.translitModel,
				},
				method: requestType.POST,
			}),
			transformResponse(baseQueryReturnValue: IModelTrainSend) {
				baseQueryReturnValue.from = setLocalTime(baseQueryReturnValue.from);
				baseQueryReturnValue.to = setLocalTime(baseQueryReturnValue.to);
				baseQueryReturnValue.inputs.map((tag) => {
					if (tag.points) {
						tag.points.map((point) => {
							point.d = setLocalTime(point.d);
						});
					}
				});
				baseQueryReturnValue.outputs?.map((tag) => {
					if (tag.points) {
						tag.points.map((point) => {
							point.d = setLocalTime(point.d);
						});
					}
				});
				return baseQueryReturnValue;
			},
		}),
		getAutoLayout: builder.mutation<IModelPeriod, QueryAutoLayoutBody>({
			query: (query) => {
				const updatedBody = {
					...query.modelBody,
					algorithm: query.autoLayoutAlgorithm,
				};

				return {
					url: `${prefixUrl}/autolayout`,
					params: {
						sessionId: query.sessionId,

						eventType: query.cacheType,
					},
					method: requestType.POST,
					body: updatedBody,
				};
			},
			transformResponse(baseQueryReturnValue: IModelPeriod) {
				for (const key in baseQueryReturnValue) {
					baseQueryReturnValue[key as periodType] =
						baseQueryReturnValue[key as periodType].map(localizeDatePeriod);
				}
				return baseQueryReturnValue;
			},
		}),
	}),
});

export const {
	useGetParameterInputsQuery,
	useLazyGetParameterInputsQuery,
	useSetSaveExistModelMutation,
	useSetWithoutTrainMutation,
	useArcValuesByTagMutation,
	useGetTrainMutation,
	useGetSimulateMutation,
	useGetSaveModelMutation,
	useGetExistedSaveTagMutation,
	useModelDeleteMutation,
	useTagDeleteMutation,
	useSaveNewTagMutation,
	useGetAutoLayoutMutation,
} = modelingApi;
