import { ReactNode } from "react";

import { RouteObject, createBrowserRouter } from "react-router-dom";

import { Navigate } from "react-router-dom";
import ErrorPage from "./core/pages/ErrorPage/ErrorPage";
import StartingPage from "./core/pages/StartingPage/StartingPage";
import { checkPermissions } from "./core/services/slices/permissions-checker";

import { ListKind } from "./modules/events-list-module/types/list-kind";
import { ClaimEnum } from "./types/system/i-permissions";

const defaultAuthGuard = (claimType: ClaimEnum) => {
	if (!checkPermissions(claimType)) {
		throw new Response(claimType, { status: 403 });
	} else {
		return new Response("", { status: 200 });
	}
};

export enum ROUTES_PATHS {
	MONITORING = "monitoring/:selectedObjectId?/cards-list?/:cardId?",
	ANALYSYS = "analysys/:selectedObjectId?/model?/:modelName?",
	ECONOMIC = "economic-effect/cards-list?/:cardId?",
	MODELING_MODELS = "constructor",
	MODELING_TRAINING = "constructor/model/:modelId",
	EVENTS_LIST = "events-list",
	MODELS_LIST = "models-list",
}

export function builderRouting(
	getBasicLayout: (_body?: ReactNode) => ReactNode
) {
	const childrenRoutes: RouteObject[] = [];
	childrenRoutes.push({
		loader: () => defaultAuthGuard(ClaimEnum.CanViewMonitoring),
		path: ROUTES_PATHS.MONITORING,
		async lazy() {
			const { MonitoringPage } = await import(
				"./modules/monitoring-module/monitoring-module-routing"
			);
			return { Component: MonitoringPage };
		},
	});
	childrenRoutes.push({
		path: ROUTES_PATHS.ANALYSYS,
		loader: () => defaultAuthGuard(ClaimEnum.CanViewAnalitics),
		async lazy() {
			const { ModelAnalysisPage } = await import(
				"./modules/models-analysys-module/models-module-routing"
			);
			return { Component: ModelAnalysisPage };
		},
	});
	childrenRoutes.push({
		path: ROUTES_PATHS.EVENTS_LIST,
		loader: () => defaultAuthGuard(ClaimEnum.CanViewEventList),
		async lazy() {
			const { EventsList } = await import(
				"./modules/events-list-module/events-list-module-routing"
			);

			// key нужен чтобы компонент заново маунтился при изменении пути
			return {
				element: <EventsList key="events-list" type={ListKind.events} />,
			};
		},
	});
	childrenRoutes.push({
		path: ROUTES_PATHS.MODELS_LIST,
		loader: () => defaultAuthGuard(ClaimEnum.CanViewModelsList),
		async lazy() {
			const { EventsList } = await import(
				"./modules/events-list-module/events-list-module-routing"
			);

			// key нужен чтобы компонент заново маунтился при изменении пути
			return {
				element: <EventsList key="models-list" type={ListKind.models} />,
			};
		},
	});
	childrenRoutes.push({
		path: ROUTES_PATHS.MODELING_MODELS,
		loader: () => defaultAuthGuard(ClaimEnum.CanViewModelConstructor),
		async lazy() {
			const { ModelsList } = await import(
				"./modules/models-settings-module/models-settings-routing"
			);
			return { Component: ModelsList };
		},
	});

	childrenRoutes.push({
		path: ROUTES_PATHS.MODELING_TRAINING,
		loader: () => defaultAuthGuard(ClaimEnum.CanViewModelConstructor),

		async lazy() {
			const { ModelTraining, TrainingError } = await import(
				"./modules/models-settings-module/models-settings-routing"
			);
			return { Component: ModelTraining, ErrorBoundary: TrainingError };
		},
	});

	childrenRoutes.push({
		path: ROUTES_PATHS.ECONOMIC,
		loader: () => defaultAuthGuard(ClaimEnum.CanViewEconomic),

		async lazy() {
			const { EconomicEffect } = await import(
				"./modules/economic-effect-module/economic-effect-routing"
			);
			return { Component: EconomicEffect };
		},
	});
	/* Добавил стартовую страницу  */
	childrenRoutes.push({
		path: "starting-page",
		Component: StartingPage,
	});
	/* Добавил стартовую страницу  */
	childrenRoutes.push({
		path: "*",
		element: <Navigate to="/starting-page" />,
	});
	return createBrowserRouter([
		{
			path: "/",
			element: getBasicLayout(),
			children: childrenRoutes,
			errorElement: getBasicLayout(<ErrorPage />),
		},
	]);
}
