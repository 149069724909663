import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { EventsListUIElementsSettings } from "../../../modules/events-list-module/types/events-list-ui-settings";
import {
	ExcelReport,
	ExcelReportPayload,
} from "../../../modules/events-list-module/types/excel-report";
import { TimeConfig } from "../../../types/common/i-timer-config";
import { IModel } from "../../../types/api/i-api-model";
import { ICard } from "../../../types/api/i-card";
import { EventListUIElementConfigGroup } from "../../../types/common/ui-data";

type EventsListSliceState = {
	timeConfig: TimeConfig | null;
	modelsOutputs: IModel[] | null;
	modelsByIdObjectHierarchy: IModel[] | null;
	filteredCards: ICard[];
	uiElementsSettings: EventsListUIElementsSettings;
	isColumnsChanging: boolean;
	excelReport: ExcelReport;
};

const initialState: EventsListSliceState = {
	timeConfig: null,
	modelsOutputs: null,
	modelsByIdObjectHierarchy: null,
	filteredCards: [],
	uiElementsSettings: {
		[EventListUIElementConfigGroup.table]: {},
		[EventListUIElementConfigGroup.stats]: {},
	},
	isColumnsChanging: false,
	excelReport: {},
};

const eventsListSlice = createSlice({
	name: "eventsListSlice",
	initialState,
	reducers: {
		changeEventsListTimeConfig: (
			state,
			action: PayloadAction<TimeConfig | null>
		) => {
			state.timeConfig = action.payload;
		},
		selectFilteredCards: (state, action: PayloadAction<ICard[]>) => {
			state.filteredCards = action.payload;
		},
		selectModelsOutputs: (state, action: PayloadAction<IModel[] | null>) => {
			state.modelsOutputs = action.payload;
		},
		selectModelsByIdObjectHierarchy: (
			state,
			action: PayloadAction<IModel[] | null>
		) => {
			state.modelsByIdObjectHierarchy = action.payload;
		},
		selectUIElementsSettings: (
			state,
			action: PayloadAction<EventsListUIElementsSettings>
		) => {
			state.uiElementsSettings = action.payload;
		},
		selectIsColumnsChanging: (state, action: PayloadAction<boolean>) => {
			state.isColumnsChanging = action.payload;
		},
		updateExcelReportData: (
			state,
			{ payload }: PayloadAction<ExcelReportPayload>
		) => {
			const { table, stats } = payload;

			if (stats) {
				state.excelReport.stats = stats;
			} else if (table) {
				state.excelReport.table = table;
			}
		},
		resetEventSliceState: () => initialState,
	},
});

export const {
	selectFilteredCards,
	selectModelsOutputs,
	selectModelsByIdObjectHierarchy,
	changeEventsListTimeConfig,
	selectUIElementsSettings,
	selectIsColumnsChanging,
	updateExcelReportData,
	resetEventSliceState,
} = eventsListSlice.actions;

export default eventsListSlice;
