import { environment } from "../../environment";
import { AlgorithmItem } from "./../../modules/models-settings-module/parts/ModelTrainingHeader/ModelTrainingHeader";

import { periodType } from "../../types/common/i-period";
import { parseTagsFromExpression } from "./model-preparation";

import dayjs from "dayjs";
import { ITreeObject } from "../../types/api/i-object";
import { modifySessionId } from "./modify-sessionid";
import {
	ISOJSONDateFormat,
	JSONDateFormat,
	setLocalTime,
} from "./time-formats";

import {
	IAlgorithm,
	IArcValues,
	IModel,
	IModelTrain,
	IModelTrainSend,
	ITagInputs,
} from "../../types/api/i-api-model";
import { Point } from "../../types/common/i-factor-analisys-info";
import {
	IAddInfo,
	IAnalysysRawSeries,
	IDateRange,
	IModelConfig,
	IModelInstance,
	IModelPeriod,
	IOptional,
	IRawTimeSeries,
	ITag,
	ITagProp,
	ITrainingTag,
	cacheType,
	modelType,
} from "../../types/common/i-model";
import { getModelParamByType } from "./get-param-by-type";
import { getParamByType } from "./model-finding";
import { ChartType } from "../../types/system/i-chart";
import { FactorAnalysisMetricsValues } from "../../modules/monitoring-module/parts/FactorAnalysisPart/FactorAnalysisPart";

const BASIS_DATERANGE: IDateRange = {
	end: dayjs().toJSON(),
	begin: dayjs().subtract(1, "months").toJSON(),
};
const EXPRESSION_TYPES = [
	modelType.Expression,
	modelType.ModelRule,
	modelType.OfflineRule,
	modelType.VirtualRule,
];
const EXPRESSION_ALGORITHMS = [
	modelType.ModelRule,
	modelType.OfflineRule,
	modelType.VirtualRule,
];
const ANOMALY_TYPES = [
	modelType.Vanilla_AE,
	modelType.LSTM_AE,
	modelType.Deep_AE,
	modelType.Variational_AE,
	modelType.Hotelling_T2,
];
const DEFAULT_MODEL_URL: string = environment.restModelSettings;
const DEFAULT_REPEAT_PERIOD = 600;
const DEFAULT_INTEGRAL_VALUE_TAGS_PROPS: ITagProp = {
	type: "raw",
	interval: "24h",
}; //999 айди тега
const DEFAULT_INPUT_TAGS_PROPS: ITagProp = {
	type: "avg",
	interval: "3h",
	window: "10m",
}; //0-899 айдишники для моделей отказов
const DEFAULT_INPUT_TAGS_PROPS_CNN: ITagProp = {
	type: "avg",
	window: "10m",
	interval: "8h",
};

const DEFAULT_INPUT_TAGS_PROPS_LSTM_AE: ITagProp = {
	type: "avg",
	window: "10m",
	interval: "5h",
};

const DEFAULT_INPUT_OFFLINE_TAGS_PROPS_LSTM_AE: ITagProp = {
	type: "avg",
	window: "10m",
	interval: "5h",
};

const DEFAULT_INPUT_OFFLINE_TAGS_PROPS: ITagProp = { type: "Cur" }; //900-998 айдишники
export function transformModelConfig(model: IModelInstance): IModelInstance {
	const perfomanceParam = getParamByType(model?.outputs || [], "PERFOMANCE");
	const qualityValue =
		perfomanceParam?.points && perfomanceParam?.points?.length > 0
			? perfomanceParam.points[0]?.v
			: null;
	const modelSettings = getModelSetting(model);
	const trainingPeriodsRange = getTrainingDateRangesModel(modelSettings);

	const pushedRow: IModelInstance = {
		...model,
		trainingPeriods: trainingPeriodsRange,
		config: modelSettings,
		qualityMetric: qualityValue,
	};
	return pushedRow;
}

export const getModelSetting = (model: IModel): IModelConfig =>
	typeof model.settings == "string"
		? (JSON.parse(model.settings) as IModelConfig)
		: model.settings;

export const getBasisPeriodModel = (
	modelConfig: IModelConfig
): IDateRange | undefined => {
	let result: IDateRange | undefined;
	for (const key in modelConfig.configs) {
		if (key == "Period")
			result = {
				begin: dayjs(modelConfig.configs[key][0].begin).format(ISOJSONDateFormat),
				end: dayjs(modelConfig.configs[key][0].end).format(ISOJSONDateFormat),
			};
	}
	if (result) return result;
};

export const getTrainingDateRangesModel = (
	modelConfig?: IModelConfig
): IModelPeriod => {
	const result: IModelPeriod = {
		[periodType.Failurs]: [],
		[periodType.Normal]: [],
		[periodType.Anomaly]: [],
		[periodType.AnomalyManual]: [],
		[periodType.Offline]: [],
		[periodType.Signalize]: [],
		[periodType.Block]: [],
	};
	const entries = Object.entries(periodType);
	if (modelConfig?.configs) {
		for (const key in modelConfig?.configs) {
			const findPeriod = entries.find((item) => item[0] == key);
			// Оффлайн разметку не берем с настроек модели, только с тега STATUS
			if (findPeriod && key !== periodType.Offline) {
				const modelConfigs: IDateRange[] = modelConfig.configs[key];
				const newModelConfigs: IDateRange[] = [];
				for (const dateRange of modelConfigs) {
					const newRange = {
						begin: dayjs(dateRange.begin).format(ISOJSONDateFormat),
						end: dayjs(dateRange.end).format(ISOJSONDateFormat),
					};
					newModelConfigs.push(newRange);
				}
				result[findPeriod[1] as periodType] = newModelConfigs;
			}
		}
	}
	return result;
};

export const getAlertDuration = (modelConfig: IModelConfig): string => {
	let result = null;
	for (const key in modelConfig.configs) {
		if (key == "AlertDuration") result = modelConfig.configs[key];
	}
	return result;
};

export const getModelInstanceByOutput = (
	model: IModel,
	modelTypes: AlgorithmItem[]
): IModelInstance => {
	const modelSettings = getModelSetting(model);
	const basisDateRange = getBasisPeriodModel(modelSettings);
	const perfomanceParam = getModelParamByType(model.outputs, "PERFOMANCE");
	const qualityValue =
		perfomanceParam?.points && perfomanceParam?.points?.length > 0
			? perfomanceParam.points[0]?.v
			: null;
	const result: IModelInstance = {
		...model,
		description: model.description || model.shortDescription,
		shortDescription: model.shortDescription || model.name,
		type:
			(modelTypes.length &&
				(modelTypes.find((item) => item.id == model.idAlgorithm)
					?.label as modelType)) ||
			modelType.Vanilla_AE,
		basisPariod: basisDateRange || BASIS_DATERANGE,
		signalDuration: getAlertDuration(modelSettings),
		inputsTags: [],
		equipStatusTags: [],
		config:
			typeof model.settings == "string"
				? (JSON.parse(model.settings) as IModelConfig)
				: model.settings,
		consts: model.consts || {},
		trainingData: [],
		modelStatus: {
			isExist: true,
			isNeedToRetrain: false,
			isRetrained: false,
			isNeedToParseOffline: !modelSettings?.configs?.[periodType.Offline]?.length
				? true
				: false,
		},
		qualityMetric: qualityValue,
	};

	return result;
};

export const createNewModel = (type = modelType.Vanilla_AE): IModelInstance => {
	const basisConsts: { [key: string]: string | number } =
		type == modelType.Expression
			? {
					expression: "",
			  }
			: {};
	return {
		id: null,
		name: "",
		description: "",
		shortDescription: "",
		recommendations: "",
		type: type,
		idConfig: 0,
		idObject: "",
		signalDuration: "",
		inputsTags: [],
		trainingData: [],
		consts: basisConsts || {},
		modelStatus: {
			isExist: false,
			isNeedToRetrain: true,
			isRetrained: false,
			isNeedToParseOffline: true,
		},

		date: "",
		idAlgorithm: 0,
		idObjectParent: "",
		objectName: "",
		settings: "",
		metrics: "",
		outputs: [],
		qualityMetric: null,
	};
};

export const prepeareTagsToPISDK = (
	tags: ITag[],
	modelId: string,
	sessionId: string,
	dateRange: IDateRange,
	cacheType: cacheType,
	src?: string | null
): IArcValues => ({
	data: {
		tags: tags.map((item) => item.tagName),
		type: "raw",
		sessionId: modifySessionId(sessionId, modelId),
		dateStart: dateRange.begin,
		dateEnd: dateRange.end,
	},
	cacheType: cacheType,
	object: src || environment.sourceType || "",
});

export const parseTagToTraingTag = (
	tag: ITag,
	type: modelType,
	index?: number,
	customProps?: ITagProp | null
): ITrainingTag => ({
	name: tag.tagName,
	i: index || tag.id,
	points: [],
	props: {
		...(customProps || genDefaultTagProps(index || tag.id, type)),
		H: tag.max,
		L: tag.min,
	},
});

function genDefaultTagProps(id: number, type: modelType): ITagProp {
	if (id === 999) {
		return DEFAULT_INTEGRAL_VALUE_TAGS_PROPS;
	}
	if (id >= 0 && id <= 899) {
		return DEFAULT_INPUT_TAGS_PROPS;
	}
	return type === modelType.LSTM_AE
		? DEFAULT_INPUT_OFFLINE_TAGS_PROPS_LSTM_AE
		: DEFAULT_INPUT_OFFLINE_TAGS_PROPS;
}

export function transformSendModelByType(
	payloadModel: IModelTrainSend,
	type: modelType
): IModelTrainSend {
	switch (type) {
		case modelType.Expression:
			return prepareExpressionSendModel(payloadModel);
		default:
			return payloadModel;
	}
}

const getCustomProps = (modelBody: IModelInstance) => {
	switch (modelBody.type) {
		case modelType.CNN:
			return DEFAULT_INPUT_TAGS_PROPS_CNN;
		case modelType.LSTM_AE:
			return DEFAULT_INPUT_TAGS_PROPS_LSTM_AE;
		default:
			return null;
	}
};

function prepareExpressionSendModel(
	payloadModel: IModelTrainSend
): IModelTrainSend {
	const tags = parseTagsFromExpression(payloadModel.consts?.expression);
	const transformedPayload = { ...payloadModel, tags };
	return transformedPayload;
}
export const parseModelBodyToSaveInCache = (
	modelBody: IModelInstance,
	sessionId: string
): IModelTrain => {
	const customProps = getCustomProps(modelBody);

	return {
		inputs: modelBody.inputsTags
			.map((item) =>
				parseTagToTraingTag(item, modelBody.type, undefined, customProps)
			)
			.concat(
				(modelBody.equipStatusTags || []).map((val, ind) =>
					parseTagToTraingTag(val, modelBody.type, 900 + ind, customProps)
				)
			),
		model: modelBody.shortDescription,
		description: modelBody.description,
		recommendations: modelBody.recommendations,
		translitModel: modelBody.name.toUpperCase(),
		sessionId: modifySessionId(sessionId, modelBody?.id),
		objectId: modelBody.idObject,
		cacheType: !modelBody.id ? cacheType.Create : cacheType.Edit,
		settings: {
			url: DEFAULT_MODEL_URL,
			repeatPeriodSec: DEFAULT_REPEAT_PERIOD,
			canCreateIncident: false,
		},
		from: modelBody.basisPariod?.begin || BASIS_DATERANGE.begin,
		to: modelBody.basisPariod?.end || BASIS_DATERANGE.end,
		consts: modelBody.consts,
		optionals: {
			AlertDuration: modelBody.signalDuration
				? modelBody.signalDuration + "m"
				: "",
			...(modelBody.trainingPeriods &&
				transformModelPeriodToOptional(modelBody.trainingPeriods)),
		},
	};
};

export function getObjectFullPathById(
	idObject: string,
	allObjects: ITreeObject[]
): string {
	let parentObject = allObjects.find((item) => item.id == idObject);
	if (!parentObject) return "-";
	let resPath = parentObject.name;
	parentObject = parentObject.parent;
	while (parentObject && parentObject.idType != 1) {
		resPath = parentObject.name + ">" + resPath;
		parentObject = parentObject.parent;
	}
	return resPath;
}

const getTranslatePeriodName = (rusName: string): string => {
	let resultName = "";
	const valuesPeriodsType = Object.values(periodType);
	const keysPeriodsType = Object.keys(periodType);
	const findIndexPeriod = valuesPeriodsType.findIndex((item) => item == rusName);
	if (findIndexPeriod > -1) resultName = keysPeriodsType[findIndexPeriod];
	return resultName;
};
export const transformModelPeriodToOptional = (
	modelPeriod: IModelPeriod
): IOptional => {
	const result: IOptional = {};
	for (const period in modelPeriod) {
		const translateName = getTranslatePeriodName(period);
		result[translateName] = modelPeriod[period as periodType];
	}
	return result;
};

export const uploadModelAfterTrain = (
	curModelBody: IModelInstance
): IModelInstance => curModelBody;

export const changeModelTypeByIdAlgorithm = (
	idAlgoritm?: number,
	allAlgorithms?: IAlgorithm[]
): modelType => {
	if (!idAlgoritm || !allAlgorithms) return modelType.Vanilla_AE;
	const findedAlgoritm = allAlgorithms.find((item) => item.id == idAlgoritm);
	if (!findedAlgoritm) return modelType.Vanilla_AE;
	return findedAlgoritm.name as modelType;
};

export function parseContribData(
	newContribData: ITrainingTag[] | IAnalysysRawSeries[],
	modelName?: string
): IAddInfo {
	const resultMap = new Map<string, Map<string, number>>();
	try {
		for (const contrib of newContribData) {
			let mapKey = contrib.name;
			if (mapKey.toLowerCase().includes("value")) {
				mapKey = "resValue";
			} else {
				/* Сделал обработку, вдру */
				mapKey = modelName
					? contrib.name
							.replace(`_${modelName?.toUpperCase()}`, "")
							.replace(`_Contrib`, "")
					: contrib.name.split("_")[0];
			}
			const valueMap = new Map<string, number>();
			for (const point of contrib.points || []) {
				valueMap.set(point.d, point.v);
			}
			resultMap.set(mapKey, valueMap);
		}
	} catch (error) {
		console.log("ERROR", error);
	}
	return resultMap;
}

export function filterExpressionModelsByIdAlgorithm(
	models: IModel[],
	idAlg: number
): IModel[] {
	return models.filter(
		(model) => model.idAlgorithm == idAlg && model.outputs[0]
	);
}

export function arcToOfflinePeriod(
	rawData: (ITrainingTag | IRawTimeSeries)[],
	equipedTags?: ITag[] | null
): IDateRange[] {
	const newPeriods: IDateRange[] = [];
	if (!equipedTags) return newPeriods;
	let index = 900;
	// находим периоды офлайн разметки для каждого тега состояния в отдельности
	for (const item of rawData) {
		const targetTag = equipedTags.find((obj) => obj.tagName == item.name);
		if (!targetTag) continue;
		let newPeriodStart = "";
		let newPeriodEnd = "";
		let isPeriodStart = false;
		const targetMin = Number(targetTag.min);
		const targetMax = Number(targetTag.max);
		for (let i = 0; i < item.points.length; i++) {
			if (
				(item.points[i]?.v < targetMin || item.points[i]?.v > targetMax) &&
				isPeriodStart == false
			) {
				newPeriodStart = item.points[i]?.d;
				isPeriodStart = true;
			}
			if (
				item.points[i + 1] &&
				item.points[i + 1]?.v >= targetMin &&
				item.points[i + 1]?.v <= targetMax &&
				(item.points[i]?.v < targetMin || item.points[i]?.v > targetMax) &&
				isPeriodStart == true
			) {
				newPeriodEnd = item.points[i]?.d;
				isPeriodStart = false;
			} else if (
				!item.points[i + 1] &&
				(item.points[i]?.v < targetMin || item.points[i]?.v > targetMax) &&
				isPeriodStart == true
			) {
				newPeriodEnd = item.points[i]?.d;
				isPeriodStart = false;
			}
			if (newPeriodStart != "" && newPeriodEnd != "" && isPeriodStart == false) {
				const periodPush: IDateRange = {
					begin: newPeriodStart,
					end: newPeriodEnd,
				};
				index++;
				newPeriods.push(periodPush);
				newPeriodStart = "";
				newPeriodEnd = "";
				isPeriodStart = false;
			}
		}
	}
	const concatedPeriods = genConcatedOfflinePeriods(newPeriods);
	return concatedPeriods;
}

// алгоритм отсеивания входящих и пересекающихсе периодов
export function genConcatedOfflinePeriods(newPeriods: IDateRange[]) {
	// финальные объединенные периоды
	const concatedPeriods: IDateRange[] = [];
	// если периодов нет
	if (!newPeriods?.length) {
		return concatedPeriods;
	}
	// сортируем все периоды по дате начала от большего к меньшему
	const sortedPeriods = newPeriods.sort(
		(a, b) =>
			dayjs(a.begin, ISOJSONDateFormat).valueOf() -
			dayjs(b.begin, ISOJSONDateFormat).valueOf()
	);
	// переменные для отслеживания текущего периода, который определяем (по умолчанию первый период)
	let newPeriodBegin = sortedPeriods[0].begin;
	let newPeriodEnd = sortedPeriods[0].end;

	// по каждому периоду
	for (let i = 0; i < sortedPeriods.length; i++) {
		// если следующий период есть
		if (sortedPeriods[i + 1]) {
			//если конец отслеживаемого периода меньше чем начало следующего
			if (sortedPeriods[i + 1].begin > newPeriodEnd) {
				//отслеживаемый период не имеет включений и пересечений с другими периодами
				concatedPeriods.push({ begin: newPeriodBegin, end: newPeriodEnd });
				//переходим к следующему периоду
				newPeriodBegin = sortedPeriods[i + 1].begin;
				newPeriodEnd = sortedPeriods[i + 1].end;
				continue;
			}
			//если начало следующего периода меньше либо равно концу отслеживаемого
			if (sortedPeriods[i + 1].begin <= newPeriodEnd) {
				//если и конец следующего периода меньше чем конец отслеживаемого, то данный период включается в отслеживаемы и не нужен
				if (sortedPeriods[i + 1].end <= newPeriodEnd) {
					continue;
				}
				//если конец больше, то отодвигаем конец отслеживаемого дальше
				newPeriodEnd = sortedPeriods[i + 1].end;
			}
		} else {
			//если это последний период, проверяем что данный период еще не записан в финальный список
			if (
				concatedPeriods.at(-1) &&
				concatedPeriods.at(-1)!.begin != newPeriodBegin &&
				concatedPeriods.at(-1)!.end != newPeriodEnd
			) {
				concatedPeriods.push({ begin: newPeriodBegin, end: newPeriodEnd });
			} else if (sortedPeriods.at(-1)) {
				//если офлайн период 1 общий и включает в себя все периоды
				concatedPeriods.push({
					begin: newPeriodBegin,
					end: sortedPeriods.at(-1)!.end || "",
				});
			}
		}
	}
	return concatedPeriods;
}

export const updateAnomalyPeriodFromTag = (
	periodInfo: IModelPeriod,
	statusTag: ITrainingTag | IRawTimeSeries,
	chartType: ChartType,
	valueTag?: ITrainingTag | IRawTimeSeries,
	threshold?: number
): IModelPeriod => {
	const newPeriodsInfo = { ...periodInfo };
	const resultsAnomalyRanges: IDateRange[] = [];
	const resultsOfflineRanges: IDateRange[] = [];
	const detectedPeriods: { [index: number]: Point[] } = { 0: [] };

	if (!statusTag.points) return periodInfo;
	let startOfflinePoint: Point | null = null;
	for (let index = 1; index < statusTag.points.length; index++) {
		const prevPoint = statusTag.points[index - 1];
		const curPoint = statusTag.points[index];

		const prevValuePoint: Point | undefined = valueTag?.points?.[index - 1];
		const curValuePoint: Point | undefined = valueTag?.points?.[index];

		const isTrashAndValueExist =
			prevValuePoint && curValuePoint && threshold !== undefined;

		const newPeriodWithThresh =
			isTrashAndValueExist &&
			prevValuePoint?.v < threshold &&
			curValuePoint?.v > threshold;

		const newPeriodWithoutThresh =
			!threshold && prevPoint.v == 0 && curPoint.v == 1;

		const endPeriodWithThresh =
			isTrashAndValueExist && curValuePoint?.v > threshold;

		const endPeriodWithoutThresh = !threshold && curPoint.v == 1;

		const newOfflnePeriod =
			(prevPoint.v === 0 ||
				prevPoint.v === 1 ||
				(index === 1 && prevPoint.v === 2)) &&
			curPoint.v === 2;
		if (newOfflnePeriod) {
			if (index === 1 && prevPoint.v === 2) {
				startOfflinePoint = prevPoint;
			} else {
				startOfflinePoint = curPoint;
			}
		}
		const endOfflinePeriod =
			(prevPoint.v === 2 && (curPoint.v === 1 || curPoint.v === 0)) ||
			(curPoint.v === 2 && !statusTag.points[index + 1]);
		if (newPeriodWithThresh || newPeriodWithoutThresh) {
			detectedPeriods[Object.keys(detectedPeriods).length] = [prevPoint, curPoint];
		} else if (endPeriodWithThresh || endPeriodWithoutThresh) {
			detectedPeriods[Object.keys(detectedPeriods).length - 1].push(curPoint);
		}
		if (endOfflinePeriod && startOfflinePoint) {
			resultsOfflineRanges.push({
				begin: startOfflinePoint.d,
				end: curPoint.d,
			});
			startOfflinePoint = null;
		}
	}
	for (const key in detectedPeriods) {
		if (detectedPeriods[key].length > 1) {
			resultsAnomalyRanges.push({
				begin: detectedPeriods[key][0]?.d,
				end: detectedPeriods[key]?.at(-1)?.d || dayjs().toJSON(),
			});
		}
	}
	newPeriodsInfo[periodType.Anomaly] = resultsAnomalyRanges;
	newPeriodsInfo[periodType.Offline] =
		chartType == ChartType.analysis
			? resultsOfflineRanges
			: periodInfo[periodType.Offline];
	return newPeriodsInfo;
};

export const transformModelAfterTrain = (
	trainBody: IModelTrainSend,
	isRetrain?: boolean,
	curModel?: IModelInstance
): IModelInstance => {
	const resultsPoints: IRawTimeSeries[] = (trainBody.outputs || [])
		.filter((tag) => tag.i == 1 || tag.i == 2 || tag.i == 0)
		.map((item) => ({
			name: item.name,
			unit: "-",
			points: item.points || [],
		}));

	const tagForDetectAnomaly = (trainBody.outputs || []).find(
		(item) => item.i == 0
	);
	let periodInfo = curModel?.trainingPeriods;
	if (tagForDetectAnomaly && periodInfo)
		periodInfo = updateAnomalyPeriodFromTag(
			periodInfo,
			tagForDetectAnomaly,
			ChartType.modeling
		);
	const updatedModelBody = {
		...curModel,
		modelStatus: {
			...curModel?.modelStatus,
			isNeedToRetrain: false,
			isRetrained: isRetrain,
		},
		name: trainBody?.translitModel || curModel?.name,
		resultsData: resultsPoints,
		consts: trainBody.consts || curModel?.consts,
		trainingPeriods: periodInfo,
		addInfo: parseContribData(trainBody.outputs || [], curModel?.name),
	};
	return updatedModelBody as IModelInstance;
};

export function saveNewPeriod(
	curModel: IModelInstance,
	period: [periodType, string[]]
) {
	const model = { ...curModel };
	if (model.trainingPeriods) {
		const newPeriods = { ...model.trainingPeriods };
		newPeriods[period[0]] = [
			...newPeriods[period[0]],
			{
				begin: dayjs(period[1][0]).format(JSONDateFormat),
				end: dayjs(period[1][1]).format(JSONDateFormat),
			},
		];
		model.trainingPeriods = newPeriods;
	}
	return model;
}

export function getTagsNames(tags?: ITag[] | null): string[] | undefined {
	return tags ? tags.map((el) => el.tagName) : undefined;
}

export function updateOffline(newModel: IModelInstance): IModelInstance {
	const basePeriods: IModelPeriod = {
		[periodType.Normal]: [],
		[periodType.Failurs]: [],
		[periodType.Anomaly]: [],
		[periodType.AnomalyManual]: [],
		[periodType.Offline]: [],
		[periodType.Signalize]: [],
		[periodType.Block]: [],
	};

	const filteredOfflineTags = newModel.trainingData
		.filter((el: IRawTimeSeries) => el.points.length)
		.filter((el: IRawTimeSeries) =>
			(newModel.equipStatusTags || []).map((el) => el.tagName).includes(el.name)
		);

	const offlinePeriodFromArc = arcToOfflinePeriod(
		filteredOfflineTags,
		newModel?.equipStatusTags
	);

	const newPeriods = newModel?.trainingPeriods
		? {
				...newModel.trainingPeriods,
				[periodType.Offline]: offlinePeriodFromArc || [],
		  }
		: {
				...basePeriods,
				[periodType.Offline]: offlinePeriodFromArc || [],
		  };

	newModel = {
		...newModel,
		modelStatus: {
			...newModel.modelStatus,
			isNeedToParseOffline: false,
		},
		trainingPeriods: newPeriods,
	};
	return newModel;
}
// добавление пропсов к входным тегам
export function propsFinder(data: ITagInputs[], param: ITrainingTag) {
	const props = data.find((item) => item.parameter.tag === param.name)?.props;
	if (props) {
		return JSON.parse(props);
	}
	return undefined;
}

export const localizeDatePeriod = (dp: IDateRange): IDateRange => ({
	begin: setLocalTime(dp.begin),
	end: setLocalTime(dp.end),
});

export function isExpressionType(type?: modelType | string): boolean {
	return EXPRESSION_TYPES.includes(type as modelType);
}

export function isExpressionAlgorithm(type?: modelType | string): boolean {
	return EXPRESSION_ALGORITHMS.includes(type as modelType);
}

export function isAnomalyType(type?: modelType | string): boolean {
	return ANOMALY_TYPES.includes(type as modelType);
}

export const getModelMetrics = (metrics?: FactorAnalysisMetricsValues) => {
	const onlineQuality = metrics?.ONLINE_QUALITY
		? Number(metrics?.ONLINE_QUALITY.toFixed(2))
		: 0;

	const offlineQuality = metrics?.OFFLINE_QUALITY
		? Number(metrics?.OFFLINE_QUALITY.toFixed(2))
		: 0;

	return `${onlineQuality}%/${offlineQuality}%`;
};

export const parseTresholdFromModel = (trainModel: IModelInstance): number => {
	try {
		return (trainModel?.consts?.["threshold"] as number) || 0;
	} catch (error) {
		return 0;
	}
};
