import { Responses403 } from "@consta/gpn-responses/Responses403";
import { Responses404 } from "@consta/gpn-responses/Responses404";
import { Responses500 } from "@consta/gpn-responses/Responses500";
import React, { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router";
import styles from "./ErrorPage.module.scss";

import { Button } from "@consta/uikit/Button";
import { environment } from "../../../environment";
import { convertJSONtoBlob } from "../../../shared/utils/convertJSONtoBlob";
import { useSetFeedbackMutation } from "../../services/API/api-feedback-file";
import notification from "antd/es/notification";
import { openNotification } from "../../../shared/components/SideNotificationComponent.ts/SideNotificationComponent";

const ErrorPage: React.FC = () => {
	const error = useRouteError();
	console.error(error);
	const navigate = useNavigate();
	const [sendFeedback, resultSendFeedBack] = useSetFeedbackMutation();

	const [notificationApi, contextHolder] = notification.useNotification();

	useEffect(() => {
		if (resultSendFeedBack.isError) {
			openNotification(
				notificationApi,
				"Ошибка при отправке сообщения",
				`Повторите, пожалуйста, позже`,
				30,
				"bottomRight",
				"error"
			);
		} else if (resultSendFeedBack.isSuccess) {
			openNotification(
				notificationApi,
				"Данные об ошибке отправлены команде разработке",
				`Спасибо за обратную связь`,
				30,
				"bottomRight",
				"success"
			);
		}
	}, [resultSendFeedBack]);

	function sendClaimRequest(claim = "noInfo") {
		const formData: FormData = new FormData();
		formData.append(
			"Content",
			`Прошу предоставить доступ\n${claim}\n[Версия интерфейса: ${environment.curVersion}]`
		);
		formData.append("Status", "Ошибка доступа ");
		sendFeedback(formData);
	}
	function sendErrorFeedback(error: unknown) {
		const formData: FormData = new FormData();
		formData.append(
			"Content",
			`Произошла критическая ошибка ${JSON.stringify(
				error
			)}\n[Версия интерфейса: ${environment.curVersion}]`
		);
		const blobFile = convertJSONtoBlob(error);
		formData.append("Files", blobFile, "ErrorLog");
		formData.append("Status", "Критическая ошибка системы");
		sendFeedback(formData);
	}
	function getError404Page() {
		return <Responses404 />;
	}
	function getError500Page() {
		return (
			<Responses500
				actions={
					<div className={styles.actions}>
						<Button
							size="m"
							view="primary"
							label="Сообщить об ошибке команде разработки"
							onClick={() => sendErrorFeedback(error)}
						/>
					</div>
				}
			/>
		);
	}
	function getError403Page() {
		return (
			<Responses403
				actions={
					<div className={styles.actions}>
						<Button
							size="m"
							view="ghost"
							label="Перейти на стартовую страницу"
							onClick={() => navigate("/starting-page")}
						/>
						<Button
							size="m"
							view="primary"
							label="Запросить доступ"
							onClick={() => sendClaimRequest((error as { data?: string })["data"])}
						/>
					</div>
				}
			/>
		);
	}
	function getResponseByStatucCode(statusCode?: number) {
		switch (statusCode) {
			case 403:
				return getError403Page();
			case 404:
				return getError404Page();
			default:
				return getError500Page();
		}
	}
	return (
		<div className={styles.errorPage}>
			{getResponseByStatucCode((error as { status: number }).status)}
			{contextHolder}
		</div>
	);
};
export default ErrorPage;
