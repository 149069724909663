/*Клеймы для ролевой матрицы  */
export enum ClaimEnum {
	CanAll = "CanAll", //Суперпользователь
	CanViewModelConstructor = "CanViewModelConstructor", // Просморт страницы конструктора
	CanEditNotification = "CanEditNotification", // Редактирование карточки

	CanCreateAndEditModels = "CanCreateAndEditModels", //1 - Создание и редактирование моделей
	CanViewMonitoring = "CanViewMonitoring", //2 - Просмотр данных и результатов расчетов по оборудованию
	CanSetConfigutationSPAS = "CanSetConfigutationSPAS", // 3 - Работа по изменению перечня данных системы
	CanViewLog = "CanViewLog", // 4 - Просмотр логов
	CanEditRoleConstructor = "CanEditRoleConstructor", //5 - Ролевой конструктор (редактирование)
	CanViewRoleConstructor = "CanViewRoleConstructor", // 6 - Просмотр конструктора ролей (accessMatrix)
	CanEditAdminPanel = "CanEditAdminpanel", //7 Административная панель (редактирование)
	CanEditNotification_ZUP = "CanEditNotification_ZUP", //8.1 - Работа с оповещениями (ЦУП)
	CanEditNotification_UN = "CanEditNotification_UN", //8.2 - Работа с оповещениями (УН)
	CanViewAlertEventLog = "CanViewAlertEventLog", // 9 Работа с журналом событий по оповещениям
	CanViewModelsEventLog = "CanViewModelsEventLog", // 10 - Работа с журналом событий по моделям
	CanEditFinancialEffect = "CanEditFinancialEffect", // 11 - Работа с экраном экономического эффекта
	CanViewSecurityEventLog = "CanViewSecurityEventLog", //12 - Просмотр журнала событий безопасности
	CanViewAdminPanel = "CanViewAdminPanel", //13 - Административная панель (чтение)
	CanViewAnalitics = "CanViewAnalitics", //14 - Доступ к экрану аналитики
	CanViewEventList = "CanViewEventList", //15 - Доступ к журналу событий
	CanViewModelsList = "CanViewModelsList", //16 - Доступ к журналу моделей
	CanViewEconomic = "CanViewEconomic", //17 - Доступ к журналу моделей
}
