import { IAlgorithm, IModel } from "../../types/api/i-api-model";
import { IParameter } from "../../types/api/i-data";
import { modelType } from "../../types/common/i-model";

export function getParamByType(
	params: IParameter[],
	type: string,
	name = ""
): IParameter | undefined {
	const targetParam = params.find(
		(item) => item.tag.toUpperCase() == (name + "_" + type).toUpperCase()
	);
	if (targetParam) {
		return targetParam;
	}
	return params.find((item) =>
		item.tag.toLowerCase().includes(type.toLowerCase())
	);
}

export function algorithmFinder(algorithms: IAlgorithm[], model: IModel) {
	if (algorithms.length > 0) {
		const targetAlg = algorithms.find((elem) => elem.id === model.idAlgorithm);
		if (targetAlg) {
			switch (targetAlg.name) {
				case "LSTM_AutoEncoder":
					return modelType.LSTM_AE;
				case "Hotelling_T2":
					return modelType.Hotelling_T2;
				case "Vanilla_AE":
					return modelType.Vanilla_AE;
				case "KIP":
					return modelType.KIP;
				case "ModelRule":
					return modelType.ModelRule;
				case "Variational_AE":
					return modelType.Variational_AE;
				case "Deep_AE":
					return modelType.Deep_AE;
				case "OfflineRule":
					return modelType.OfflineRule;
				case "VirtualRule":
					return modelType.VirtualRule;
				case "CNN":
					return modelType.CNN;
			}
		}
	}
}
