import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import FileSaver from "file-saver";
import { environment } from "../../../environment";
import { getFileName } from "../../../shared/utils/file-operations";
import { urlConcat } from "../../../shared/utils/url-concat";
import { IEconomicCalculationPayload } from "../../../types/api/i-event-collector";
import {
	EconomicEffect,
	IEconomicAlertPayload,
	IEconomicGroup,
	IEconomicImpact,
	IEconomicProbability,
	IEconomicValues,
} from "../../../types/system/i-economic";
import { requestType } from "../../../types/system/i-request";
import { RootState } from "../store";

export const eventCollectorApi = createApi({
	reducerPath: "event-collector",
	baseQuery: fetchBaseQuery({
		baseUrl: urlConcat(environment.urlEventCollector.trim(), "/api/"),
		credentials: "include",
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).authManager.userToken;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		// получение всех переменных экономического расчета
		getEffectIncidentCollector: builder.query<IEconomicValues[], number>({
			query: (id: number) => ({
				url: `EffectIncidentCollector/${id}`,
			}),
		}),
		// запустить расчет экономического эффект
		getEffectIncidentCollectorCalculation: builder.query<
			IEconomicValues,
			IEconomicCalculationPayload
		>({
			query: (data: IEconomicCalculationPayload) => ({
				url: `EffectIncidentCollector/calculation/${data.id}`,
				params: {
					effect: data.effect,
				},
			}),
		}),
		// получить id карточек, по которым расчитан экономический эффект
		getEffectIncidentCollectorCalculationCardsIds: builder.query<number[], void>({
			query: () => ({
				url: `EffectIncidentCollector/calculation/cardIds`,
				params: {
					effect: EconomicEffect.Incident,
				},
			}),
		}),
		// получение всех групп переменных экономического расчета
		getEffectGroups: builder.query<IEconomicGroup[], void>({
			query: () => ({
				url: `EffectGroups`,
			}),
		}),
		// отправить сразу много переменных
		postEffectIncidentCollectorMany: builder.query<
			IEconomicValues[],
			IEconomicValues[]
		>({
			query: (data: IEconomicValues[]) => ({
				url: `EffectIncidentCollector/many`,
				method: requestType.POST,
				body: data,
			}),
		}),
		// отправить только 1 переменную
		postEffectIncidentCollector: builder.query<IEconomicValues, FormData>({
			query: (data: FormData) => ({
				url: `EffectIncidentCollector`,
				method: requestType.POST,
				body: data,
				formData: true,
			}),
		}),
		// удалить переменную
		delEffectIncidentCollector: builder.query<IEconomicValues, IEconomicValues>({
			query: (data: IEconomicValues) => ({
				url: `EffectIncidentCollector`,
				method: requestType.DELETE,
				params: {
					incidentId: data.incidentId,
					environment: data.environment,
				},
			}),
		}),
		// получение всех значений воздействий переменных
		getEffectImpact: builder.query<IEconomicImpact[], void>({
			query: () => ({
				url: `Impact`,
			}),
		}),
		// получение всех значений воздействий переменных
		getEffectProbability: builder.query<IEconomicProbability[], void>({
			query: () => ({
				url: `Probability`,
			}),
		}),
		// получение всех значений воздействий переменных
		getEffectCollectorFile: builder.query<Blob, number>({
			query: (id: number) => ({
				url: `EffectIncidentCollector/file/${id}`,
				responseHandler: async (response) => {
					const file = await response.blob();
					const name = getFileName(response.headers);
					FileSaver.saveAs(file, name);
				},
			}),
		}),
		// удалить файл
		delEffectCollectorFile: builder.query<void, number>({
			query: (id: number) => ({
				url: `EffectIncidentCollector/file/${id}`,
				method: requestType.DELETE,
			}),
		}),
		// отправить уведомление пользователю
		effectIncidentCollectorAlert: builder.query<void, IEconomicAlertPayload>({
			query: (data: IEconomicAlertPayload) => ({
				url: `EffectIncidentCollector/alert`,
				method: requestType.POST,
				params: {
					incidentId: data.incidentId,
					subject: data.subject,
					effect: data.effect,
					environmentId: data.environmentId,
				},
				body: data.persons,
			}),
		}),
	}),
});

export const {
	useLazyGetEffectIncidentCollectorQuery,
	useLazyGetEffectGroupsQuery,
	useLazyGetEffectImpactQuery,
	useLazyGetEffectProbabilityQuery,
	useLazyPostEffectIncidentCollectorManyQuery,
	useLazyPostEffectIncidentCollectorQuery,
	useLazyGetEffectIncidentCollectorCalculationQuery,
	useLazyGetEffectCollectorFileQuery,
	useLazyDelEffectCollectorFileQuery,
	useLazyGetEffectIncidentCollectorCalculationCardsIdsQuery,
	useLazyDelEffectIncidentCollectorQuery,
	useLazyEffectIncidentCollectorAlertQuery,
} = eventCollectorApi;
