import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../../../environment";

import { RootState } from "../store";
import { requestType } from "../../../types/system/i-request";
import {
	IAnalysysRawSeries,
	ITrainingTag,
} from "../../../types/common/i-model";
import {
	DataAggergatedResult,
	IGetAggregated,
	IModelData,
	IParameter,
} from "../../../types/api/i-data";
import { ITagInputs } from "../../../types/api/i-tag";
import mockUnitJSON from "./mock-unit.json";
import { setLocalTime } from "../../../shared/utils/time-formats";
import dayjs from "dayjs";
import { propsFinder } from "../../../shared/utils/models-operations";
import { ISmotrData, ISmotrPayload } from "../../../types/common/i-smotr";
import { FEATURE_FLAGS, FeaturesChecker } from "../../../feature-flags-checker";

/* Костыль для подключения к старому беку */
/* TODO: Выпилить к следующему релизу, убрать dsutp-core, вынести до этого уровня в environment ts */
const prefixUrl = FeaturesChecker.check(FEATURE_FLAGS.OLD_BACKEND_CONNECTION)
	? "Proxy"
	: "dsutp-core";

export const analysisDataApi = createApi({
	reducerPath: "analysis-data",
	baseQuery: fetchBaseQuery({
		baseUrl: environment.urlGateway.trim(),
		credentials: "include",
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).authManager.userToken;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),

	endpoints: (builder) => ({
		/* Метод для получения данных по модели  */
		getAnalysysModelData: builder.query<DataAggergatedResult, IGetAggregated>({
			async queryFn(
				{ extrapolationMinutesTime: extraMinutesTime, ...payload },
				queryApi,
				_extraOptions,
				fetchWithBQ
			) {
				/* косяки бека исправляем */
				const newPayload = {
					...payload,
					to: extraMinutesTime
						? dayjs(payload.to).add(extraMinutesTime, "m").toJSON()
						: payload.to,
				};

				const basisInfoPromise = fetchWithBQ({
					url: `${prefixUrl}/Models/Data/Aggregated/prediction`,
					method: requestType.POST,
					body: newPayload,
					signal: queryApi.signal,
				}) as Promise<{ data: IModelData }>;
				/* Возможно убрать, когда бек будет удобнее */
				const addInfoPromise = fetchWithBQ({
					url: `api/Models/InfoByName/${payload.name}`,
					method: requestType.GET,
					signal: queryApi.signal,
				}) as Promise<{ data: ITagInputs[] }>;
				const fetchingResult = await Promise.all([
					basisInfoPromise,
					addInfoPromise,
				]);
				if (!fetchingResult[0]?.data || !fetchingResult[1]?.data) {
					return {
						error: {
							status: 400,
							statusText: "Bad Request",
							data: [],
						},
					};
				}
				/* Cловарик доп инфы для моделей */
				const addInfoMap = fetchingResult[1].data.reduce(
					(cumValue, curValue) =>
						cumValue.set(curValue.parameter.tag, curValue.parameter as IParameter),
					new Map<string, IParameter>()
				);
				/* Словарик единиц измерения */
				const unitMap = mockUnitJSON.reduce(
					(cumValue, curValue) => cumValue.set(curValue.id, curValue.name),
					new Map<number, string>()
				);
				/* Метод парсинга данных */
				const parseParamToRes = (param: ITrainingTag): IAnalysysRawSeries => {
					const addInfo = addInfoMap.get(param.name);
					const unit = unitMap.get(addInfo?.idUnit || 0);
					return {
						...param,
						name: param.name,
						points: (param?.points || []).map((item) => ({
							v: item.v,
							d: setLocalTime(item.d),
						})),
						index: param.i,
						displayName: addInfo?.descriptor || param.name,
						unit: unit || "-",
						props: propsFinder(fetchingResult[1].data, param),
					};
				};

				/* Фильтрация входных параметров на текущее время */
				const filteredPredictInputValues = (
					series: IAnalysysRawSeries
				): IAnalysysRawSeries => {
					// TODO: убрать эту проверку после фикса интерполяции
					const curTimeSlice = dayjs(payload.to);
					return {
						...series,
						points: series.points.filter((item) =>
							curTimeSlice.isAfter(dayjs(item.d))
						),
					};
				};

				const result: DataAggergatedResult = {
					inputs: fetchingResult[0].data.inputs
						.map(parseParamToRes)
						.map(filteredPredictInputValues),
					outputs: fetchingResult[0].data.outputs.map(parseParamToRes),
				};
				return { data: result };
			},
		}),
		getSmotrInfo: builder.mutation<ISmotrData[], ISmotrPayload>({
			query: (obj) => ({
				url: `${environment.urlIntegration}/api/Smotr/byTags`,
				params: { plant: obj.source },
				method: requestType.POST,
				body: obj.tagsNames,
			}),
		}),
	}),
});

export const { useLazyGetAnalysysModelDataQuery, useGetSmotrInfoMutation } =
	analysisDataApi;
