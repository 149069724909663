import { ICard, IncidentEvent } from "../api/i-card";

import { IModelsParameter } from "./i-high-risk";
/* Типы состояния карточек/моделей */
export enum eventType {
	HighRisk = "HighRisk",
	HighRiskKPI = "HighRiskKPI",
	Opened = "Opened",
	Approved = "Approved",
	Closed = "Closed",
	InProgress = "InProgress",
	Monitoring = "Monitoring",
}

/* Список хранения изменений одной карточки */
export type IncidentEventMapping = Record<eventType, IncidentEvent>;
/* Информация о порядке в статусной схеме  */
export type IncidentEventStatusesOrder = Record<eventType, number>;
/* Информация по цветам у карточек */
export type IncidentEventStatusesColors = Partial<Record<eventType, string>>;

//тип статуса и его колличество по карточке
export type UpdateEventPayload = {
	typeCounter: eventType;
	currentCounter: number;
};
//тип инцидента и карточки по ним
export type RefreshCollectorPayload = {
	typeEvent: eventType;
	newEvents: IAlertCard[] | IModelsParameter[];
};
/* Тип карточки для использования на интерфейсе  */
export interface IAlertCard extends ICard {
	objectName: string;
	typeModel: string;
	plantName: string;
	plantDescription: string;
}
/* Сокращенный вариант объектов  */
export type Plant = {
	id: string;
	parentId: string;
	name: string;
};
// Необходим для Consta
export type Reason = {
	label: string;
	id: number;
};

/* Статусы карточек */
export type statusType =
	| "Opened"
	| "InProgress"
	| "Approved"
	| "Monitoring"
	| "Closed";

/* Отслеживание статусов карточек  */
export type ReasonStatus = {
	canEditReason: boolean;
	reason: Reason | undefined;
	valid: boolean;
};
