export function genFileName(name: string) {
	const nameEnd = name.split(".").at(-1);
	const nameStart = name.split("_");
	let fullName = "";
	if (nameStart.length > 1) {
		for (let i = 0; i < nameStart.length - 2; i++) {
			fullName += nameStart[i];
		}
		return fullName + "." + nameEnd;
	}
	return name;
}

export function getFileName(headers: Headers) {
	const contentDisposition = headers
		.get("Content-Disposition")!
		.split(";")
		.map((x) => x.trim());
	const filenamePairs = contentDisposition.filter((x) =>
		x.startsWith("filename*=")
	);
	if (filenamePairs.length > 0) {
		return genFileName(
			decodeURI(filenamePairs[0].split("=")[1]).split("UTF-8''")[1]
		);
	}
	return "";
}
