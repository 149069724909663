/* Типы периодов */
export enum periodType {
	Normal = "Нормальная работа",
	Failurs = "Отказы",
	Anomaly = "Аномальная работа",
	AnomalyManual = "Аномальная работа (Ручная)",
	Offline = "Оффлайн",
	Signalize = "Сигнализация",
	Block = "Блокировка",
}
export enum PeriodTypeForAnalysis {
	Normal = "Нормальная работа",
	Failurs = "Отказы",
	Anomaly = "Аномальная работа",
	AnomalyManual = "Аномальная работа (Ручная)",
	Offline = "Оффлайн",
	Predict = "Прогноз",
}
export type periodTypeChart = periodType | PeriodTypeForAnalysis;
export enum periodTypeENG {
	Normal = "Normal",
	Failurs = "Failurs",
	Anomaly = "Anomaly",
	AnomalyManual = "AnomalyManual",
	Offline = "Offline",
}

/* Базовый тип для периода  */
export type IPeriod = {
	type: periodTypeChart;
	dateStart: string;
	dateEnd: string;
	id: number;
};
/* Настройки видимости различных периодов */
export type IPeriodVisibleSettings = {
	[periodName in periodType]: boolean;
};

export type IDateRange = { begin: string; end: string };

//Действия над периодом
export enum periodChangeEvent {
	Save = "Save",
	Delete = "Delete",
	Change = "Change",
}
/* Периоды на графиках */
export interface IChartPeriod extends IPeriod {
	color: string;
}
