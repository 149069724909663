import { Header, HeaderButton, HeaderModule } from "@consta/uikit/Header";
import { Loader } from "@consta/uikit/Loader";
import { Modal } from "@consta/uikit/Modal";
import { Select } from "@consta/uikit/Select";
import { Text } from "@consta/uikit/Text";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./HeaderPart.module.scss";

import { useAuth } from "react-oidc-context";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../services/store";
import FeedbackPart from "../FeedbackPart/FeedbackPart";
import { HEADER_ROUTES, SelectItem } from "./haeder-routes";

import { ThemeToggler } from "@consta/uikit/ThemeToggler";
import { throttle } from "lodash";
import { environment } from "../../../environment";
import { parseFullUserName } from "../../../shared/utils/parse-full-user-name";

import { setTheme } from "../../services/slices/interface-manager";
import SignoutPart from "../SignoutPart/SignoutPart";
import {
	ThemeType,
	AVIABLE_THEME_CONFIGS,
} from "../../../styles/themes-config";

const HeaderPart: React.FC = () => {
	const auth = useAuth();
	const userFullName = useSelector(
		(state: RootState) => state.authManager.userName
	);
	const userRole = useSelector(
		(state: RootState) => state.authManager.currentRole
	);

	const [isloader, setIsloader] = useState<boolean>(false);
	const [feedbackOpen, setFeedbackOpen] = React.useState(false);
	const [signoutOpen, setSignoutOpen] = React.useState(false);
	const [userName, setFullName] = useState<string>("");
	const navigate = useNavigate();
	const urlLocation = useLocation();
	const [selectedPage, setSelectedPage] = useState<SelectItem | null>();

	useEffect(() => {
		if (userFullName != undefined) {
			const itogFullName: string = parseFullUserName(userFullName);
			if (itogFullName != undefined) {
				setFullName(itogFullName);
			}
		}
	}, [userFullName]);
	useEffect(() => {
		const baseAdress = `/${urlLocation.pathname.split("/")[1]}`;

		const curPage = getCurPage(baseAdress);

		setSelectedPage(curPage);
	}, [urlLocation.pathname]);

	function getCurPage(url: string): SelectItem {
		return HEADER_ROUTES.find((item) => item.url == url) || HEADER_ROUTES[0];
	}

	const throttledNavigation = throttle(navigateToPage, 200);

	useEffect(() => {
		if (selectedPage) throttledNavigation(selectedPage);
	}, [selectedPage]);

	function onPageChange(value: SelectItem | null) {
		setSelectedPage(value);
	}
	function navigateToPage(page: SelectItem) {
		const url = page?.url;
		if (location.href.includes(url)) return;
		if (url) navigate(url);
	}

	function renderSelectorRouter() {
		return (
			<Select
				placeholder="Выберите значение"
				data-analytics-id="header-moduleselect-selector"
				size="m"
				view="clear"
				items={HEADER_ROUTES}
				value={selectedPage}
				onChange={({ value }) => onPageChange(value)}
			/>
		);
	}

	function feedbackShow(isOpen: boolean) {
		setFeedbackOpen(isOpen);
	}

	function signoutHandler() {
		setIsloader(true);
		auth.signoutSilent();
	}
	const sliceTheme = useSelector(
		(state: RootState) => state.interfaceManager.currentTheme
	);

	const [curTheme, setCurTheme] = useState<ThemeType>(sliceTheme);
	useEffect(() => {
		setCurTheme(sliceTheme);
	}, [sliceTheme]);
	const dispatch = useDispatch();
	function handleChangeTheme(curTheme: ThemeType) {
		dispatch(setTheme(curTheme));
	}
	return (
		<>
			<Header
				className={styles.body}
				leftSide={
					<>
						<HeaderModule className={styles.headerModule}>
							{renderSelectorRouter()}
						</HeaderModule>
					</>
				}
				rightSide={
					<>
						<HeaderModule indent="m">
							<ThemeToggler
								size="s"
								items={AVIABLE_THEME_CONFIGS}
								value={curTheme}
								getItemKey={(item: { label: string }) => item.label}
								getItemLabel={(item) => item.label}
								getItemIcon={(item) => item.icon}
								onChange={({ value }) => handleChangeTheme(value)}
								direction="downStartLeft"
							/>
						</HeaderModule>

						<HeaderModule indent="m">
							{
								<div className={styles.userInfo}>
									<Text as="p" size="s" title={userName}>
										{userName}
									</Text>
									<Text as="p" size="s" view="secondary" title={userRole}>
										{userRole}
									</Text>
								</div>
							}
						</HeaderModule>
						<HeaderModule indent="m">
							{
								<HeaderButton
									label="Сменить уч. запись"
									data-analytics-id="header-logout-button"
									onClick={() => setSignoutOpen(true)}
								/>
							}
							{
								<HeaderButton
									title={`Версия: ${environment.curVersion}`}
									data-analytics-id="header-feedback-button"
									label="Обратная связь"
									onClick={() => setFeedbackOpen(true)}
								/>
							}
						</HeaderModule>
					</>
				}
			/>
			<Modal
				isOpen={feedbackOpen}
				hasOverlay
				className={styles.feedbackModal}
				onClickOutside={() => setFeedbackOpen(false)}
				onEsc={() => setFeedbackOpen(false)}>
				<FeedbackPart
					userCredentials={{ userName, userRole }}
					setFeedbackOpen={feedbackShow}
					isFeedbackOpen={feedbackOpen}
				/>
			</Modal>
			<Modal
				isOpen={signoutOpen}
				hasOverlay
				onClickOutside={() => setSignoutOpen(false)}
				onEsc={() => setSignoutOpen(false)}
				className={styles.signoutModal}>
				<SignoutPart
					setSignoutOpen={setSignoutOpen}
					signoutHandler={signoutHandler}
				/>
			</Modal>
			<Modal isOpen={isloader} hasOverlay className={styles.signoutLoadingModal}>
				<Loader className={styles.plug} size="m" />
			</Modal>
		</>
	);
};

export default HeaderPart;
