import { environment } from "./../../../environment";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
	IInitModelAction,
	IModelInstance,
} from "../../../types/common/i-model";

import dayjs from "dayjs";
import { ISOJSONDateFormat } from "../../../shared/utils/time-formats";
import { trainingModelSteps } from "../../../types/common/i-status";
import { ITrainingPageStatus, IPageStatus } from "../../../types/system/i-page";

// Менеджер создания модели
type IModelTrainingManager = {
	selectedModelBody: IModelInstance | null | undefined;
	modelHistory: IInitModelAction[];
	trainingStatusState: ITrainingPageStatus;
};
export const INIT_STATUS: ITrainingPageStatus = {
	arcValues: IPageStatus.idle,
	training: IPageStatus.idle,
	saving: IPageStatus.idle,
	autoLayout: IPageStatus.idle,
};
const INITIAL_STATE_MODELS_TRAINING_MANAGER: IModelTrainingManager = {
	selectedModelBody: null,
	modelHistory: [],
	trainingStatusState: INIT_STATUS,
};
const modelsTrainingSlice = createSlice({
	name: "modelTrainingManager",
	initialState: INITIAL_STATE_MODELS_TRAINING_MANAGER,
	reducers: {
		initTrainingModel: (
			state,
			action: PayloadAction<IInitModelAction | null | undefined>
		) => {
			state.selectedModelBody = action.payload?.modelState || null;
			if (!action.payload || action.payload.changeInfo === undefined) return;
			if (
				state.modelHistory[0]?.modelState &&
				state.modelHistory[state.modelHistory.length - 1]?.modelState.name !==
					action.payload.modelState.name
			) {
				state.modelHistory = [];
			} else if (state.modelHistory.length === environment.modelHistoryCapacity) {
				state.modelHistory.shift();
			}
			state.modelHistory.push({
				changeInfo: action.payload?.changeInfo,
				modifyDate: dayjs().format(ISOJSONDateFormat),
				modelState: {
					...action.payload.modelState,
					modelStatus: {
						isNeedToParseOffline: !action.payload.modelState.id,
						isExist: Boolean(action.payload.modelState.id),
						isNeedToRetrain: true,
						isRetrained: false,
					},
				},
			});
		},
		changeTrainingStatus: (
			state,
			action: PayloadAction<{ step: trainingModelSteps; status: IPageStatus }>
		) => {
			const { step, status } = action.payload;
			if (state.trainingStatusState[step] == status) return;
			const newTrainingStatus = statusUpdateHelper(
				step,
				status,
				state.trainingStatusState
			);
			state.trainingStatusState = newTrainingStatus;
		},
	},
});

function statusUpdateHelper(
	step: trainingModelSteps,
	status: IPageStatus,
	state: ITrainingPageStatus
): ITrainingPageStatus {
	switch (step) {
		case trainingModelSteps.arcValues:
			return { ...INIT_STATUS, [step]: status };
		case trainingModelSteps.training:
			return { ...state, saving: IPageStatus.idle, [step]: status };
		default:
			return { ...state, [step]: status };
	}
}

export const { initTrainingModel, changeTrainingStatus } =
	modelsTrainingSlice.actions;

export default modelsTrainingSlice;
