import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../../../environment";

import { transformObjectsToTreeNodes } from "../../../shared/utils/transform-objects-to-tree-nodes";
import { urlConcat } from "../../../shared/utils/url-concat";
import { RootState } from "../store";

import { setLocalTime } from "../../../shared/utils/time-formats";
import { FEATURE_FLAGS, FeaturesChecker } from "../../../feature-flags-checker";

import {
	IModel,
	IModelFilterPayload,
	IAlgorithm,
	ITagsPayloadInfo,
} from "../../../types/api/i-api-model";
import { IParameter } from "../../../types/api/i-data";
import {
	ITreeObject,
	IObject,
	NSIObject,
	ObjectType,
} from "../../../types/api/i-object";
import { ITrainResult, IModelState } from "../../../types/common/i-model";
import { requestType } from "../../../types/system/i-request";

const isOldBackend = FeaturesChecker.check(
	FEATURE_FLAGS.OLD_BACKEND_CONNECTION
);

const objectsTypesUrl = isOldBackend
	? "NSI/ObjectTypes"
	: `${environment.urlCore}/ObjectTypes/types`;

export const factorAnalisysLibraryApi = createApi({
	reducerPath: "factor-analisis",
	baseQuery: fetchBaseQuery({
		baseUrl: urlConcat(environment.urlGateway.trim(), "/api/"),
		credentials: "include",
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).authManager.userToken;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ["Models"],
	endpoints: (builder) => ({
		/* Вытягиваю все объекты */
		getAllObjects: builder.query<ITreeObject[], void>({
			query: () => ({
				url: "NSI/Objects",
			}),
			transformResponse: (response: IObject[]): ITreeObject[] =>
				transformObjectsToTreeNodes(response),
		}),
		getAccessList: builder.query<any, void>({
			query: () => ({
				url: "Auth/AccessList",
			}),
		}),
		/* Получаю все дочерние объекты */
		getObjectsByIdParent: builder.query<IObject[], string>({
			query: (id: string) => ({
				url: "NSI/ObjectsByIdParent",
				params: { idParent: id },
			}),
		}),
		getParametersByIdObject: builder.query<IParameter[], string>({
			query: (id: string) => ({
				url: "NSI/ParametersByIdObject",
				params: { idObject: id },
			}),
		}),
		/* Получаю все модели, связанные с объектом (только оборудование) */
		getModelsByIdObject: builder.query<IModel[], string>({
			query: (id: string) => ({
				url: "Models/byIdObject",
				params: { idObject: id },
			}),
		}),
		/* Получаю все модели, связанные с объектом (любой объект) */
		getModelsByIdObjectHierarchy: builder.query<IModel[], string>({
			query: (id: string) => ({
				url: "Models/objectHierarchy",
				params: {
					idObject: id,
				},
			}),
			transformResponse(baseQueryReturnValue: IModel[]) {
				return baseQueryReturnValue?.map((model) => ({
					...model,
					output: model?.outputs?.map((output) => ({
						...output,
						points: output?.points?.map((point) => ({
							...point,
							d: setLocalTime(point?.d),
						})),
					})),
				}));
			},
		}),
		/* Получаю информацию по модели DEPRECATED*/
		getOutputsById: builder.query<IModel[], number[]>({
			query: (ids: number[]) => ({
				url: "Models/outputsById",
				method: requestType.POST,
				body: ids,
			}),
			providesTags: (result) =>
				result
					? [
							...result.map(({ id }) => ({ type: "Models" as const, id: Number(id) })),
					  ]
					: ["Models"],
			transformResponse(baseQueryReturnValue: IModel[]) {
				baseQueryReturnValue.map((model) => {
					model.outputs.map((output) => {
						output.points?.map((point) => {
							if (point) {
								point.d = setLocalTime(point.d);
							}
						});
					});
				});
				return baseQueryReturnValue;
			},
		}),
		/* Получаю информацию по модели с нужными outputs CANARY*/
		getOutputsByIdFilter: builder.query<IModel[], IModelFilterPayload>({
			query: (ids: IModelFilterPayload) => ({
				url: "Models/outputsByIdFilter",
				method: requestType.POST,
				body: ids,
			}),
			providesTags: (result) =>
				result
					? [
							...result.map(({ id }) => ({ type: "Models" as const, id: Number(id) })),
					  ]
					: ["Models"],
			transformResponse(baseQueryReturnValue: IModel[]) {
				baseQueryReturnValue.map((model) => {
					model.outputs.map((output) => {
						output.points?.map((point) => {
							if (point && "d" in point) {
								point.d = setLocalTime(point.d);
							}
						});
					});
				});

				return baseQueryReturnValue;
			},
		}),
		/* Текущие значения по перечню тегов */
		getCurrentValuesByTags: builder.mutation<IParameter[], string[]>({
			query: (tags: string[]) => ({
				url: "Datas/CurrentValueByTags",
				method: requestType.POST,
				body: tags,
			}),
			transformResponse(baseQueryReturnValue: IParameter[]) {
				baseQueryReturnValue.map((result) => {
					result.points?.map((point) => {
						point.d = setLocalTime(point.d);
					});
				});
				return baseQueryReturnValue;
			},
		}),
		/* Добавить новый объект */
		sendObject: builder.mutation<NSIObject, IObject>({
			query: (obj: IObject) => ({
				url: "NSI/Object",
				method: requestType.POST,
				body: { ...obj },
			}),
		}),
		/* Редактировать объект */
		redactObject: builder.mutation<NSIObject, IObject>({
			query: (obj: IObject) => ({
				url: "NSI/Object",
				params: { id: obj.id },
				method: requestType.PUT,
				body: obj,
			}),
		}),
		/* Удалить объект */
		/* Добавил удаление лишних пробелов, чтобы исключить косяки, со стороны пользователей  */
		deleteObject: builder.mutation<NSIObject, string>({
			query: (objId: string) => ({
				url: `NSI/Object?objectId=${objId}`,
				params: { objectId: objId },
				method: requestType.DELETE,
			}),
		}),
		getAlgorithms: builder.query<IAlgorithm[], void>({
			query: () => ({
				url: "NSI/Algorithms",
			}),
		}),
		//типизировать
		getInfoByIdName: builder.query<any, string>({
			query: (modelName: string) => ({
				url: `Models/InfoByName/${modelName}`,
			}),
		}),
		saveTrainResult: builder.mutation<any, ITrainResult>({
			query: (obj) => ({
				url: "Datas/values/byTag",
				params: { tag: obj.tag },
				method: requestType.POST,
				body: obj.points,
			}),
		}),
		valuesByNamesPreparedInfoRange: builder.mutation<unknown, ITagsPayloadInfo>({
			query: (obj) => ({
				url: "Datas/ValuesByNamesPreparedInfoRange",
				method: requestType.POST,
				body: obj,
			}),
		}),
		modelState: builder.mutation<any, IModelState>({
			//включение-выключение модели
			query: (obj) => ({
				url: `Models/state/${obj.id}`,
				params: { enabled: obj.state },
				method: requestType.PUT,
			}),
			invalidatesTags: (result, error, arg) => [{ type: "Models", id: arg.id }],
		}),
		getModelConfigs: builder.query<any, number>({
			query: (id: number) => ({
				url: `Configs/${id}`,
			}),
		}),
		getNotification: builder.mutation<any, IModelState>({
			//включение-выключение оповещений по модели
			query: (obj) => ({
				url: `Models/notification/${obj.id}`,
				params: { enabled: obj.state },
				method: requestType.PUT,
			}),
			invalidatesTags: (result, error, arg) => [{ type: "Models", id: arg.id }],
		}),
		getObjectsTypes: builder.query<ObjectType[], void>({
			query: () => objectsTypesUrl,
		}),
	}),
});

export const {
	useGetAllObjectsQuery,
	useLazyGetAccessListQuery,
	useLazyGetObjectsByIdParentQuery,
	useLazyGetModelsByIdObjectHierarchyQuery,
	useLazyGetOutputsByIdQuery,
	useLazyGetOutputsByIdFilterQuery,
	useLazyGetModelsByIdObjectQuery,
	useLazyGetInfoByIdNameQuery,
	useLazyGetModelConfigsQuery,
	useGetCurrentValuesByTagsMutation,
	useLazyGetParametersByIdObjectQuery,
	useGetOutputsByIdQuery,
	useSendObjectMutation,
	useRedactObjectMutation,
	useModelStateMutation,
	useGetNotificationMutation,
	useSaveTrainResultMutation,
	useValuesByNamesPreparedInfoRangeMutation,
	useLazyGetAlgorithmsQuery,
	useGetAlgorithmsQuery,
	useDeleteObjectMutation,
	useGetObjectsTypesQuery,
} = factorAnalisysLibraryApi;

export function getLastFileFactorAnalisysLibrary() {
	window.open(
		urlConcat(environment.urlGateway.trim(), "/api/") +
			"FactorAnalisysProperties/last/file",
		"_blank"
	);
}
