import React from "react";

import { Layout } from "@consta/uikit/Layout";
import { Text } from "@consta/uikit/Text";
import { Button } from "@consta/uikit/Button";

import styles from "./SignoutPart.module.scss";

const SignoutPart: React.FC<{
	setSignoutOpen: (_value: boolean) => void;
	signoutHandler: () => void;
}> = ({ setSignoutOpen, signoutHandler }) => (
	<Layout className={styles.body} direction="column">
		<Layout className={styles.header}>
			<Text size="l" view="primary">
				Выход
			</Text>
		</Layout>
		<Layout className={styles.content}>
			<Button
				data-analytics-id="signoutmodal-cancel-button"
				size="m"
				view="clear"
				label="Отмена"
				width="default"
				onClick={() => setSignoutOpen(false)}
			/>
			<Button
				size="m"
				view="primary"
				label="Выйти"
				data-analytics-id="signoutmodal-approve-button"
				width="default"
				onClick={() => {
					setSignoutOpen(false);
					signoutHandler();
				}}
			/>
		</Layout>
	</Layout>
);

export default SignoutPart;
