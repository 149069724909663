import {
	NotificationInstance,
	NotificationPlacement,
} from "antd/es/notification/interface";
import { NotificationType } from "../../../types/system/i-page";
import { Text } from "@consta/uikit/Text";
import { IconClose } from "@consta/icons/IconClose";

export const openNotification = (
	api: NotificationInstance,
	title: string,
	message: string,
	duration: number,
	placement: NotificationPlacement,
	type: NotificationType
) => {
	api[type]({
		message: (
			<Text size="m" view="primary">
				{title}
			</Text>
		),
		description: (
			<Text size="s" view="secondary">
				{addLineBreaks(message)}
			</Text>
		),
		duration,
		placement,
		className: "training-notification",
		closeIcon: <IconClose />,
	});
};

const addLineBreaks = (message: string) =>
	message.split("\n").map((line, index) => (
		<span key={index}>
			{line}
			<br />
		</span>
	));
