import dayjs from "dayjs";

export const JSONDateFormat = "YYYY-MM-DDTHH:mm";
export const ISOJSONDateFormat = "YYYY-MM-DDTHH:mm"; //отдельно для исо пока так (после релиза парни в питоне поправят, и тут будет ISO формат, но пока там используется такой же как дефолтный)
export const DefaultDateFormat = "YYYY-MM-DD HH:mm";
export const DefaultSecDateFormat = "YYYY-MM-DD HH:mm:ss";
export const DefaultYearFormat = "YYYY-MM-DD";
export const DotsYearFormat = "YYYY.MM.DD";
export const DotsDateFormat = "YYYY.MM.DD HH:mm";
export const TooltipDateFormat = "DD.MM.YYYY HH:mm";
export const DayMonthDateFormat = "DD.MM";
export const JSONSecDateFormat = "YYYY-MM-DDTHH:mm:ss";
export const ListDateFormat = "DD.MM.YYYY HH:mm";
export const DefaultSecTimeFormat = "HH:mm:ss";
export const DefaultTimeFormat = "HH:mm";

// проверка дат с API
export function setLocalTime(data: string): string {
	if (data?.at(-1) !== "Z") {
		return data + "Z";
	}
	return data;
}

export function getUTCTimeZoneDate(date: string) {
	return (
		dayjs(date)
			.subtract(-new Date().getTimezoneOffset() / 60, "h")
			.format(JSONSecDateFormat) + "Z"
	);
}
