import { SelectPeriodItem } from "../../modules/models-analysys-module/types/select-time-item";

export enum uiConfigNames {
	source = "DataSource",
	selectedMonitoringObject = "SelectedMonitoringObject",
	incidentsEventsScheme = "IncidentsEventsScheme",
	boundariesDict = "BoundariesTagsInfo",
	eventsListTableColumns = "EventsListTableColumns",
	eventsListSettings = "EventsListSettings",
	modelsListTableColumns = "ModelsListTableColumns",
	modelsListSettings = "ModelsListSettings",
	selectDateSettings = "SelectDateSettings",
}
/* Тип для истчников данных */
export type TSourcesList = {
	[idObject: string]: string;
	default: string;
};
/* Тип для конфигурации статусной схемы */
/*Перечень полей доступных для редактирования  полей в карточке*/
export type TIncidentsForms = "subject" | "comment" | "reason";
/* accessLevel, где defaultLevel- это дефолтный доступ, доступ по клейму, доступ по клейму*/
export type TIncicdentTemplate<T> = {
	[accessLevel: string]: T[];
	defaultLevel: T[];
};
export type TIncidentsEventsScheme = {
	[status: string]: {
		order: number;
		accessEditMatrix: TIncicdentTemplate<TIncidentsForms>;
		accessViewMatrix: TIncicdentTemplate<TIncidentsForms>;
		aviableSteps: TIncicdentTemplate<string>;
	};
	default: {
		order: number;
		accessEditMatrix: TIncicdentTemplate<TIncidentsForms>;
		accessViewMatrix: TIncicdentTemplate<TIncidentsForms>;
		aviableSteps: TIncicdentTemplate<string>;
	};
};
export type SelectedMonitoringObject = {
	id: string;
};

/* Типизация словаря границ */
export type BoundTagInfo = {
	HH?: number; // верхняя граница блокировки
	LL?: number; // нижняя граница блокировки
	H?: number; // верхняя граница сигнализации
	L?: number; // нижняя граница сигнализации
};
export type BoundsTagDicts = {
	[tag: string]: BoundTagInfo;
};

export type ColumnSettings = {
	name: string;
	displayName: string;
	cellType: string;
	width: number;
	excelName: string;
};

export enum EventListUIElementConfigGroup {
	table = "table",
	stats = "stats",
}

export type EventListUIElementConfigItem = {
	name: string;
	group: EventListUIElementConfigGroup;
	description: string;
	isChecked: boolean;
	isDisabled: boolean;
};

/*Список перечисление возможных типов конфигов */
export type uiConfigType = {
	[uiConfigNames.source]: TSourcesList;
	[uiConfigNames.selectedMonitoringObject]: SelectedMonitoringObject;
	[uiConfigNames.incidentsEventsScheme]: TIncidentsEventsScheme;
	[uiConfigNames.boundariesDict]: BoundsTagDicts;
	[uiConfigNames.eventsListTableColumns]: ColumnSettings[];
	[uiConfigNames.eventsListSettings]: EventListUIElementConfigItem[];
	[uiConfigNames.modelsListTableColumns]: ColumnSettings[];
	[uiConfigNames.modelsListSettings]: EventListUIElementConfigItem[];
	[uiConfigNames.selectDateSettings]: SelectPeriodItem[];
};

/* Перечень доступных конфигов */
export type ConfigsList = {
	[key in uiConfigNames]?: uiConfigType[key] | null;
};
