import { ClaimEnum } from "../../../types/system/i-permissions";
import { store } from "../store";

export function checkPermissions(claim: string): boolean {
	try {
		const permissions = store?.getState()?.authManager?.permissions || [];
		if (permissions.includes(ClaimEnum.CanAll)) {
			return true;
		}
		return permissions.includes(claim);
	} catch (error) {
		console.log("claim check error:", error);
		return false;
	}
}
