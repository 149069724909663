import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	eventType,
	IAlertCard,
	RefreshCollectorPayload,
	UpdateEventPayload,
} from "../../../types/common/i-alert-cards";
import { IStatus } from "../../../types/common/i-status";
import { IReasonsCount } from "../../../types/common/i-reasons";
import { IModelsParameter } from "../../../types/common/i-high-risk";
import {
	statisticModelType,
	UpdateStatisticModelPayload,
} from "../../../types/common/i-model";
import { ICard } from "../../../types/api/i-card";
import { IOperational } from "../../../types/api/i-statistics";

// Менеджер инцидентов мониторинга
type IMonitoringIncindents = {
	selectedCard: ICard | null | undefined; //выбранная карточка
	selectedCards: IAlertCard[] | null | undefined; //список всех карточек
	selectedUpdatedCards: IAlertCard[] | null | undefined; //обновленный, после новых карточек, список всех карточек
	selectedNewCards: IAlertCard[] | null | undefined; //новые карточки инцидентов
	selectedStatusesData: IStatus[] | null | undefined; //Статусы карточек по выбранному объекту
	selectedReasonsData: IReasonsCount[] | null | undefined; //Причины срабатываний по выбранному объекту
	modelsStatusesCounter: IModelsStatusesCounter; //статиcтика моделей (онлайн/офлайн/с проблемами)
	counter: IEventsCounter; //статистика карточек инцидентов
	collector: {
		//обновление статистики карточек инцидентов
		[collectorType in eventType]: IAlertCard[] | IModelsParameter[];
	};
	selectedOperationalData: IOperational[] | null | undefined; //Данные для динамики изменения оперативного показателя
};
type IModelsStatusesCounter = {
	onlineModelsCounter: number; //статиcтика моделей (онлайн/офлайн/с проблемами)
	offlineModelsCounter: number;
	problemsModelsCounter: number;
};
export type IEventsCounter = {
	highRiskEventsCounter: number;
	highRiskEventsCounterKPI: number;
	openedEventsCounter: number;
	inprogressEventsCounter: number;
	approvedEventsCounter: number;
	closedEventsCounter: number;
	monitoringEventsCounter: number;
};

const INITIAL_STATE_EVENT_COUNTER: IEventsCounter = {
	highRiskEventsCounter: 0,
	highRiskEventsCounterKPI: 0,
	openedEventsCounter: 0,
	inprogressEventsCounter: 0,
	approvedEventsCounter: 0,
	closedEventsCounter: 0,
	monitoringEventsCounter: 0,
};

const INITIAL_STATE_MODELS_STATUSES_COUNTER: IModelsStatusesCounter = {
	onlineModelsCounter: 0,
	offlineModelsCounter: 0,
	problemsModelsCounter: 0,
};

const INITIAL_STATE_INCINDENS_MANAGER: IMonitoringIncindents = {
	selectedCard: null,
	selectedCards: null,
	selectedUpdatedCards: null,
	selectedNewCards: [],
	selectedStatusesData: null,
	selectedReasonsData: null,
	modelsStatusesCounter: INITIAL_STATE_MODELS_STATUSES_COUNTER,
	counter: INITIAL_STATE_EVENT_COUNTER,
	collector: {
		HighRisk: [],
		HighRiskKPI: [],
		Opened: [],
		Approved: [],
		Closed: [],
		InProgress: [],
		Monitoring: [],
	},
	selectedOperationalData: null,
};
const monitoringIncindentsSlice = createSlice({
	name: "monitoringIncindents",
	initialState: INITIAL_STATE_INCINDENS_MANAGER,
	reducers: {
		selectedCard: (state, action: PayloadAction<ICard | null | undefined>) => {
			state.selectedCard = action.payload;
		},
		selectCards: (
			state,
			action: PayloadAction<IAlertCard[] | null | undefined>
		) => {
			state.selectedCards = action.payload;
		},
		selectedUpdatedCards: (
			state,
			action: PayloadAction<IAlertCard[] | null | undefined>
		) => {
			state.selectedUpdatedCards = action.payload;
		},
		selectedNewCards: (
			state,
			action: PayloadAction<IAlertCard[] | null | undefined>
		) => {
			state.selectedNewCards = action.payload;
		},
		//добавление новой карточки инцидентов
		pushNewCard: (
			state,
			action: PayloadAction<IAlertCard | null | undefined>
		) => {
			const oldArray = state.selectedNewCards;
			if (action.payload) oldArray?.push(action.payload);
			state.selectedNewCards = oldArray;
		},
		//удаление карточки инцидентов
		deleteCard: (state, action: PayloadAction<IAlertCard | null | undefined>) => {
			let oldArray = state.selectedNewCards;
			if (action.payload) {
				oldArray = oldArray?.filter(
					(item) => item.id != (action.payload as IAlertCard).id
				);
			}
			state.selectedNewCards = oldArray;
		},
		selectStatusesData: (
			state,
			action: PayloadAction<IStatus[] | null | undefined>
		) => {
			state.selectedStatusesData = action.payload;
		},
		selectReasonsData: (
			state,
			action: PayloadAction<IReasonsCount[] | null | undefined>
		) => {
			state.selectedReasonsData = action.payload;
		},
		updateStatisticModel: (
			state,
			action: PayloadAction<UpdateStatisticModelPayload>
		) => {
			const oldModelsStatuses = state.modelsStatusesCounter;
			const statusType = action.payload.typeCounter;
			const curCounter = action.payload.currentCounter;
			switch (statusType) {
				case statisticModelType.Online: {
					oldModelsStatuses.onlineModelsCounter = curCounter;
					break;
				}
				case statisticModelType.Offline: {
					oldModelsStatuses.offlineModelsCounter = curCounter;
					break;
				}
				case statisticModelType.Problems: {
					oldModelsStatuses.problemsModelsCounter = curCounter;
					break;
				}
			}
			state.modelsStatusesCounter = oldModelsStatuses;
		},
		updateEventsCounter: (state, action: PayloadAction<UpdateEventPayload>) => {
			const oldCounter = state.counter;
			const statusType = action.payload.typeCounter;
			const curCounter = action.payload.currentCounter;
			switch (statusType) {
				case eventType.HighRisk: {
					oldCounter.highRiskEventsCounter = curCounter;
					break;
				}
				case eventType.HighRiskKPI: {
					oldCounter.highRiskEventsCounterKPI = curCounter;
					break;
				}
				case eventType.Opened: {
					oldCounter.openedEventsCounter = curCounter;
					break;
				}
				case eventType.InProgress: {
					oldCounter.inprogressEventsCounter = curCounter;
					break;
				}
				case eventType.Approved: {
					oldCounter.approvedEventsCounter = curCounter;
					break;
				}
				case eventType.Closed: {
					oldCounter.closedEventsCounter = curCounter;
					break;
				}
				case eventType.Monitoring: {
					oldCounter.monitoringEventsCounter = curCounter;
					break;
				}
			}
			state.counter = oldCounter;
		},
		refreshCollector: (state, action: PayloadAction<RefreshCollectorPayload>) => {
			const statusType = action.payload.typeEvent;
			const curCollector = action.payload.newEvents;
			switch (statusType) {
				case eventType.HighRisk: {
					state.collector[eventType.HighRisk] = curCollector;
					break;
				}
				case eventType.HighRiskKPI: {
					state.collector[eventType.HighRiskKPI] = curCollector;
					break;
				}
				case eventType.Opened: {
					state.collector[eventType.Opened] = curCollector;
					break;
				}
				case eventType.Approved: {
					state.collector[eventType.Approved] = curCollector;
					break;
				}
				case eventType.Closed: {
					state.collector[eventType.Closed] = curCollector;
					break;
				}
				case eventType.InProgress: {
					state.collector[eventType.InProgress] = curCollector;
					break;
				}
				case eventType.Monitoring: {
					state.collector[eventType.Monitoring] = curCollector;
					break;
				}
			}
		},
		selectOperationalData: (
			state,
			action: PayloadAction<IOperational[] | null | undefined>
		) => {
			state.selectedOperationalData = action.payload;
		},
	},
});

export const {
	selectedCard,
	selectCards,
	selectedUpdatedCards,
	selectedNewCards,
	pushNewCard,
	deleteCard,
	selectStatusesData,
	selectReasonsData,
	updateStatisticModel,
	updateEventsCounter,
	refreshCollector,
	selectOperationalData,
} = monitoringIncindentsSlice.actions;

export default monitoringIncindentsSlice;
