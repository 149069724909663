// тип переменной экономического эффекта
// все id, кроме incidentId выдаются на беке при сохранении
export type IEconomicValues = {
	incidentId: number | null; // id карточки, к которой принадлежит переменная
	effect: EconomicEffect; // название эффекта из БД
	group: EconomicGroupsValuse; // название группы из БД
	environment: EconomicEnvironmentValues; // название переменной из БД
	subject?: string | null; // ответственный
	id?: number; // id переменной (не обязательное т.к. при создании не нужно и выдается на беке)
	effectId?: number; // id эффекта
	environmentId?: number; // id переменной
	groupId?: number; // id группы к которой пренадлежит переменная
	value?: number | null; // значение переменной
	expression?: string; // формула расчета переменной
	comment?: string; // комментарий
	environmentDescription?: string; // описание переменной на русском
	fileAlias?: string | null; // название прикрепленного файла
	unit?: string; //  единица измерения переменной
	probabilityImpactDto?: {
		// значения переменных которые указываются не в числе
		impactId: number | null;
		probabilityId: number | null;
	};
	formConfig?: IEconomicFormConfig; // конфиг формы переменной
	file?: File | null; // прикрепленный файл
};

export enum EconiomicFormKeys {
	IncidentId = "incidentId",
	Effect = "effect",
	Group = "group",
	Environment = "environment",
	Subject = "subject",
	Id = "id",
	EffectId = "effectId",
	EnvironmentId = "environmentId",
	GroupId = "groupId",
	Value = "value",
	Expression = "expression",
	Comment = "comment",
	EnvironmentDescription = "environmentDescription",
	FileAlias = "fileAlias",
	Unit = "unit",
	ProbabilityImpactDto = "probabilityImpactDto",
	ImpactId = "probabilityImpactDto.impactId",
	ProbabilityId = "probabilityImpactDto.probabilityId",
	FormConfig = "formConfig",
	File = "file",
}

// Значения еффекта в ивет коллекторе
export enum EconomicEffect {
	Incident = "INCIDENT_EFFECT",
}

// значения групп экономического эффект
export enum EconomicGroupsValuse {
	Extra = "EXTRA",
	LostProfit = "LOST_PROFIT",
	Result = "RESULT",
	MechanicalProfit = "MECHANICAL_PROFIT",
}

// значения переменных экономического эффекта
export enum EconomicEnvironmentValues {
	DowntimeCost = "DOWNITME_COST",
	Result = "RESULT",
	OverhaulCost = "OVERHAUL_COST",
	DowntimeHours = "DOWNTIME_HOURS",
	MechanicalImpactPercent = "MECHANICAL_IMPACT_PERCENT",
	ProdacionImpactPercent = "PRODUCTION_IMPACT_PERCENT",
	Extra = "EXTRA",
}

// тип группы
export type IEconomicGroup = {
	id: number;
	name: string;
};
// конфиг формы
export type IEconomicFormConfig = {
	name: EconomicEnvironmentValues;
	probabilityImpactDto: boolean;
	comment: boolean;
	fileAlias: boolean;
	subject: boolean;
	value: boolean;
};

// тип вероятности
export type IEconomicProbability = {
	id: number;
	name: string;
	description: string;
};

// тип воздействий
export type IEconomicImpact = {
	id: number;
	name: string;
	description: string;
	type: EconomicalImpactType;
};

// типы воздействий
export enum EconomicalImpactType {
	Mechanical = "Mechanical",
	Production = "Production",
}

// списки переменных для ДТО
export type IEconomicDto = {
	mechanical: IEconomicImpact[];
	prodaction: IEconomicImpact[];
	probability: IEconomicProbability[];
};

export type IEconomicAlertPayload = {
	incidentId: number;
	subject: string; // кто отправляет
	effect: EconomicEffect;
	persons: string[]; // кому отправляют
	environmentId: number;
};

export type IEconomicReportPayload = {
	effect: EconomicEffect;
	from: string;
	to: string;
};
