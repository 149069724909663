export type SelectItem = {
	label: string;
	id: number;
	url: string;
};
export const HEADER_ROUTES: SelectItem[] = [
	{
		label: "Стартовая страница",
		id: 1,
		url: "/starting-page",
	},
	{
		label: "СПАС / Мониторинг",
		id: 2,
		url: "/monitoring",
	},
	{
		label: "СПАС / Моделирование",
		id: 3,
		url: "/constructor",
	},
	{
		label: "СПАС / Аналитика",
		id: 4,
		url: "/analysys",
	},
	{ label: "СПАС / Журнал событий", id: 5, url: "/events-list" },
	{ label: "СПАС / Журнал моделей", id: 6, url: "/models-list" },
	{ label: "СПАС / Экономический эффект", id: 7, url: "/economic-effect" },
];
