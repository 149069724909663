import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiError } from "../../../types/api/api-error";

type ApiErrorSliceState = {
	error: ApiError | null;
};

const initialState: ApiErrorSliceState = {
	error: null,
};

const apiErrorSlice = createSlice({
	name: "apiErrorSlice",
	initialState,
	reducers: {
		setApiError: (state, action: PayloadAction<ApiError | null>) => {
			state.error = action.payload;
		},
	},
});

export const { setApiError } = apiErrorSlice.actions;

export default apiErrorSlice;
