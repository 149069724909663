import { isRejectedWithValue } from "@reduxjs/toolkit";
import { Middleware, MiddlewareAPI } from "redux";
import { setApiError } from "../slices/api-error-slice";

export const rtkQueryErrorLogger: Middleware =
	(api: MiddlewareAPI) => (next) => (action) => {
		if (isRejectedWithValue(action)) {
			const responseMeta = action?.meta?.baseQueryMeta?.response;

			const { status, statusText = "" } = responseMeta?.statusText
				? responseMeta
				: action.payload;

			api.dispatch(setApiError({ status, statusText }));
		}

		return next(action);
	};
