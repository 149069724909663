// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export const environment = {
	curVersion: "v1.16.1-491-gd80956c07f",
	isMocked: window?.["env"]?.["isMocked"] || false,
	urlGateway:
		window?.["env"]?.["gatewayUrl"] ||
		"http://oms02-cvtrdev.gazprom-neft.local:7860/webapi",
	urlModeling:
		window?.["env"]?.["modelingUrl"] ||
		"http://oms02-cvtrdev.gazprom-neft.local:7860/webapi/dsutp-modeling-webapi",
	urlIntegration:
		window?.["env"]?.["integrationUrl"] ||
		"http://oms02-cvtrdev.gazprom-neft.local:7860/webapi/dsutp-integration-service",
	urlEventCollector:
		window?.["env"]?.["eventCollectorUrl"] ||
		"http://oms02-cvtrdev.gazprom-neft.local:7860/webapi/dsutp-event-collector-service",
	urlReportService:
		window?.["env"]?.["reportServiceUrl"] ||
		"http://oms02-cvtrdev.gazprom-neft.local:7860/webapi/dsutp-report-service",
	urlCore:
		window?.["env"]?.["coreUrl"] ||
		"http://oms02-cvtrdev.gazprom-neft.local:7860/webapi/dsutp-core",
	urlMetrics: window?.["env"]?.["metricsUrl"] || "http://oms02-cvtrdev:38311",
	urlGrafana:
		window?.["env"]?.["grafanaUrl"] ||
		"http://oms02-cvtrdev.gazprom-neft.local:7845",
	numberOfRequestRepeat: Number(window?.["env"]?.["numberOfRequestRepeat"]) || 5,
	intervalRefresh: Number(window?.["env"]?.["intervalRefresh"]) || 180000,
	keyCloakConfig: {
		url:
			window?.["env"]?.["keycloakConfigUrl"] ||
			"http://oms02-cvtrdev.gazprom-neft.local:7856/auth",
		realm: window?.["env"]?.["keycloakConfigRealm"] || "spas",
		clientId: window?.["env"]?.["keycloakConfigClientId"] || "spa_client",
	},
	sourceType: window?.["env"]?.["sourceType"] || "PISDKMsk",
	restModelSettings:
		window?.["env"]?.["restModelSettingsUrl"] ||
		"http://oms02-cvtrdev.gazprom-neft.local:7855/calculate/",
	modelHistoryCapacity: Number(window?.["env"]?.["modelHistoryCapacity"]) || 5,
	featuresFlags: window["env"]["featuresFlags"] || ["oldBackendConnection"],
	SPAconfig: {
		url: "https://spa-back.gazprom-neft.local/",
		gpnCounterId: -11111,
	},
};
