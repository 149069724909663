import { configureStore } from "@reduxjs/toolkit";
import { factorAnalisysLibraryApi } from "./API/api-factor-analisys-library-redux";
import { CardLibraryApi } from "./API/api-card-library-redux";
import { dataSeriesApi } from "./API/api-data-series-redux";
import { feedbackFileApi } from "./API/api-feedback-file";
import keycloakManagerSlice from "./slices/auth-manager";
import modelsTrainingSlice from "./slices/model-training-slice";

import modelAnalysSlice from "./slices/model-analysis-slice";
import uiConfigSlice from "./slices/ui-config-slice";
import { uiDataConfigApi } from "./API/api-ui-data-redux";
import monitoringIncindentsSlice from "./slices/monitoring-incindents-slice";
import monitoringModelsSlice from "./slices/monitoring-models-slice";
import monitoringObjectsSlice from "./slices/monitoring-objects-slice";
import { analysisDataApi } from "./API/api-analysis-module-redux";
import { rtkQueryErrorLogger } from "./middlewares/rtkQueryErrorLogger";
import apiErrorSlice from "./slices/api-error-slice";
import eventsListSlice from "./slices/events-list-slice";
import { modelingApi } from "./API/api-modeling-redux";
import interfaceManagerSlice from "./slices/interface-manager";
import { metricsApi } from "./API/api-metrics-redux";
import economicEffectSlice from "./slices/economic-effect-slice";
import { eventCollectorApi } from "./API/api-event-collector-redux";
import { reportServiceApi } from "./API/api-reporting-service-redux";

export const store = configureStore({
	reducer: {
		[factorAnalisysLibraryApi.reducerPath]: factorAnalisysLibraryApi.reducer,
		[modelingApi.reducerPath]: modelingApi.reducer,
		[feedbackFileApi.reducerPath]: feedbackFileApi.reducer,
		[CardLibraryApi.reducerPath]: CardLibraryApi.reducer,
		[dataSeriesApi.reducerPath]: dataSeriesApi.reducer,
		[eventCollectorApi.reducerPath]: eventCollectorApi.reducer,
		[reportServiceApi.reducerPath]: reportServiceApi.reducer,
		[metricsApi.reducerPath]: metricsApi.reducer,
		[keycloakManagerSlice.name]: keycloakManagerSlice.reducer,
		[monitoringIncindentsSlice.name]: monitoringIncindentsSlice.reducer,
		[monitoringModelsSlice.name]: monitoringModelsSlice.reducer,
		[monitoringObjectsSlice.name]: monitoringObjectsSlice.reducer,
		[modelsTrainingSlice.name]: modelsTrainingSlice.reducer,
		[modelAnalysSlice.name]: modelAnalysSlice.reducer,
		[uiConfigSlice.name]: uiConfigSlice.reducer,
		[uiDataConfigApi.reducerPath]: uiDataConfigApi.reducer,
		[analysisDataApi.reducerPath]: analysisDataApi.reducer,
		[apiErrorSlice.name]: apiErrorSlice.reducer,
		[eventsListSlice.name]: eventsListSlice.reducer,
		[interfaceManagerSlice.name]: interfaceManagerSlice.reducer,
		[economicEffectSlice.name]: economicEffectSlice.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		})
			.concat(factorAnalisysLibraryApi.middleware)
			.concat(modelingApi.middleware)
			.concat(feedbackFileApi.middleware)
			.concat(CardLibraryApi.middleware)
			.concat(dataSeriesApi.middleware)
			.concat(eventCollectorApi.middleware)
			.concat(reportServiceApi.middleware)
			.concat(uiDataConfigApi.middleware)
			.concat(analysisDataApi.middleware)
			.concat(metricsApi.middleware)
			.concat(rtkQueryErrorLogger),
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
