import { Button } from "@consta/uikit/Button";
import { useNavigate } from "react-router";
import { Text } from "@consta/uikit/Text";

import { HEADER_ROUTES } from "../../parts/HeaderPart/haeder-routes";
import styles from "./StartingPage.module.scss";
const StartingPage: React.FC = () => {
	const navigate = useNavigate();
	function handleClick(url: string): void {
		navigate(url);
	}
	return (
		<div className={styles.body}>
			<Text size="l">Список сервисов</Text>
			<>
				{HEADER_ROUTES.filter((item) => !item.url.includes("starting-page")).map(
					(route) => (
						<>
							<Button
								size="s"
								key={route.id}
								label={route.label}
								view="primary"
								onClick={() => handleClick(route.url)}
							/>
						</>
					)
				)}
			</>
		</div>
	);
};
export default StartingPage;
