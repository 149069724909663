import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { Outlet, RouterProvider } from "react-router-dom";
import "./App.scss";
import HeaderPart from "./core/parts/HeaderPart/HeaderPart";
import { RootState } from "./core/services/store";

import { Theme } from "@consta/uikit/Theme";
import { useDispatch, useSelector } from "react-redux";

import {
	initAuth,
	setPermissions,
	setUserRole,
} from "./core/services/slices/auth-manager";

import { hasAuthParams, useAuth } from "react-oidc-context";
import { parseAuthInfo } from "./auth-config";
import { useLazyGetAccessListQuery } from "./core/services/API/api-factor-analisys-library-redux";
import { useLazyGetAllUIDataByIdObjectQuery } from "./core/services/API/api-ui-data-redux";

import {
	eventInterceptorMap,
	EventInterceptorProvider,
} from "@consta/uikit/EventInterceptor";
import { constaEventHandler } from "spa-consta-integration";
import { environment } from "./environment";
import { builderRouting } from "./routes-config";
import {
	CustomParamSPATypeEnum,
	sendSPAEvent,
} from "./shared/utils/spa-send-event";

import { roleType } from "./types/api/i-auth";

const USERROLE_CUSTOMPARAM_EVENT = "Роль пользователя";

const App: FC = () => {
	const user = useSelector((state: RootState) => state.authManager.userToken);
	const userName = useSelector((state: RootState) => state.authManager.userName);
	const userRole = useSelector(
		(state: RootState) => state.authManager.currentRole
	);

	const auth = useAuth();
	const [triggerGetAccessListQuery, resultAccessList] =
		useLazyGetAccessListQuery();
	const [isLoadingUserInfo, setLoadingUserInfo] = useState<boolean>(true);
	const dispatch = useDispatch();
	const [triggerGetAllUIDataByIdObject, resultGetAllUIDataConfig] =
		useLazyGetAllUIDataByIdObjectQuery();

	useEffect(() => {
		if (environment.isMocked) return;
		if (user == "unauthorized") {
			dispatch(setUserRole(roleType.unauthorized));
			return;
		}
		triggerGetAccessListQuery();
		triggerGetAllUIDataByIdObject("");
	}, [user]);
	useEffect(() => {
		if (resultAccessList.isSuccess) {
			const resData = resultAccessList.data;
			const targetRole = resData.group;
			dispatch(setUserRole(targetRole));
			dispatch(setPermissions(resData.permissions));
			setLoadingUserInfo(!resultAccessList.isSuccess);
		}
	}, [resultAccessList]);
	useEffect(() => {
		setLoadingUserInfo(!resultGetAllUIDataConfig.isSuccess);
	}, [resultGetAllUIDataConfig]);

	useEffect(() => {
		if (environment.isMocked) {
			triggerGetAccessListQuery();
			triggerGetAllUIDataByIdObject("");
			return;
		}
		if (
			!hasAuthParams() &&
			!auth.isAuthenticated &&
			!auth.activeNavigator &&
			!auth.isLoading
		) {
			auth.signinRedirect();
		}
		if (auth.isAuthenticated && auth.user) {
			const authInfo = parseAuthInfo(auth.user);
			dispatch(initAuth(authInfo));
		}
	}, [
		auth.isAuthenticated,
		auth.activeNavigator,
		auth.isLoading,
		auth.signinRedirect,
	]);

	useEffect(
		() =>
			// the `return` is important - addAccessTokenExpiring() returns a cleanup function
			auth.events.addAccessTokenExpiring(() => {
				auth.signinSilent();
			}),
		[auth.events, auth.signinSilent]
	);
	/* Подключение СПА счетчиков */
	function initSPAcounter(name: string, role: string) {
		(window as any).gpnSpaUrl = `${environment.SPAconfig.url}events`;
		(window as any).gpnCounterId = environment.SPAconfig.gpnCounterId;
		(window as any).apiVersion = "1.0";
		const headEl = document.getElementsByTagName("head")[0];
		const counterLoadingScript = document.createElement("script");
		counterLoadingScript.async = true;
		counterLoadingScript.src = `${environment.SPAconfig.url}static/counter.js`;
		counterLoadingScript.onload = function () {
			sendSPAEvent("user_login", [
				{ as_user_id: true, value: name.toLowerCase() },
				{
					name: USERROLE_CUSTOMPARAM_EVENT,
					type: CustomParamSPATypeEnum.string,
					value: role,
				},
			]);
		};

		headEl.appendChild(counterLoadingScript);
	}
	const [isInitSPA, setIsInitSPA] = useState<boolean>(false);
	useEffect(() => {
		if (
			isInitSPA ||
			userName == roleType.unauthorized ||
			userRole == roleType.unauthorized
		)
			return;
		initSPAcounter(userName, userRole);
		setIsInitSPA(true);
	}, [userName, userRole]);
	/*  */

	const sliceTheme = useSelector(
		(state: RootState) => state.interfaceManager.currentTheme
	);
	const memorizedRouterProvider = useMemo(() => {
		const curRoutersConfig = builderRouting(getLayout);
		return isLoadingUserInfo ? (
			"Loading"
		) : (
			<RouterProvider
				router={curRoutersConfig}
				fallbackElement={<p>Loading...</p>}
			/>
		);
	}, [isLoadingUserInfo]);

	return (
		/* Подкючение СПА аналитики */
		<EventInterceptorProvider
			eventHandler={constaEventHandler}
			map={eventInterceptorMap}>
			<Theme preset={sliceTheme.theme}>{memorizedRouterProvider}</Theme>
		</EventInterceptorProvider>
	);
};

function getLayout(body: ReactNode = <Outlet />): ReactNode {
	function getHeader(): ReactNode {
		return <HeaderPart />;
	}
	return (
		<div className="bodyContainer">
			<div className="header-container">{getHeader()}</div>
			{body}
		</div>
	);
}

export default App;
