import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { urlConcat } from "../../../shared/utils/url-concat";
import { environment } from "../../../environment";
import {
	ModelMetric,
	ModelMetricDto,
	ModelsMetricsGroupingByNames,
} from "../../../types/api/metric";

import { RootState } from "../store";
import { requestType } from "../../../types/system/i-request";

export const metricsApi = createApi({
	reducerPath: "metrics",
	baseQuery: fetchBaseQuery({
		baseUrl: urlConcat(environment.urlMetrics.trim(), "/api/"),
		credentials: "include",
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).authManager.userToken;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		getMetricsByLabels: builder.mutation<
			ModelsMetricsGroupingByNames,
			ModelMetricDto
		>({
			async queryFn(payload, _queryApi, _extraOptions, fetchWithBQ) {
				const queries = payload.metrics.map(
					(metric) =>
						fetchWithBQ({
							url: "v1/metrics/collector/local/dto/interval/last/byLables",
							params: {
								metric,
								from: payload.from,
								to: payload.to,
							},
							method: requestType.POST,
							body: payload.labels,
						}) as Promise<{ data: ModelMetric[] }>
				);

				const responses = await Promise.all(queries);

				const isError = responses.some((response) => !response.data);

				if (isError) {
					return {
						error: {
							status: 400,
							statusText: "Bad Request",
							data: {},
						},
					};
				}

				const metrics = responses.reduce((metricsResult, response, index) => {
					const metricName = payload.metrics[index];

					metricsResult[metricName] = response.data;

					return metricsResult;
				}, {} as ModelsMetricsGroupingByNames);

				return { data: metrics };
			},
		}),
	}),
});

export const { useGetMetricsByLabelsMutation } = metricsApi;
