import { cloneDeep } from "lodash";
import { IObject, ITreeObject } from "../../types/api/i-object";

export function transformObjectsToTreeNodes(objects: IObject[]): ITreeObject[] {
	const allNodes = objects
		.map(transformOjbectToTreeNode)
		.sort((a, b) => b.idType - a.idType);

	for (const node of allNodes) {
		const children = cloneDeep(
			allNodes
				.filter((item) => item.idObject == node.id)
				.map((item) => ({ ...item, parent: node }))
		);
		node.children = children;
		if (!node.parent) {
			const parent = allNodes.find((item) => item.id == node.idObject);
			node.parent = parent;
		}
	}
	return allNodes;
}

const transformOjbectToTreeNode = (obj: IObject): ITreeObject => ({
	...obj,
	children: [],
});
