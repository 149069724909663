import { SeriesOption, YAXisComponentOption } from "echarts";
import { Point } from "../common/i-factor-analisys-info";
//все интерфейсы для графика

export type EChartsAxisCustom = YAXisComponentOption & {
	strategy?: YAxisType;
	boundaryGap?: [number, number];
};
export type ChartOptions = {
	animation: boolean;
	textStyle: TextStyle;
	color?: string[];
	tooltip: ToolTip;
	toolbox: {
		show: boolean;
	};
	grid: Grid;
	legend: Legend;
	xAxis: any;
	yAxis: EChartsAxisCustom[];
	series: SeriesOption[];
	dataZoom?: any;
};
export type Grid = {
	right: string;
	left: string;
	top: number;
	bottom: number;
};
export type Legend = {
	show: boolean;
	selected: {
		[key: string]: boolean;
	};
};

export type YAxis = {
	type?: string;
	name?: string;
	show?: boolean;
	position?: string;
	alignTicks?: boolean;
	nameGap?: number;
	offset: number;
	axisLine?: {
		show: boolean;
		lineStyle?: {
			shadowColor?: string;
			color?: string;
			shadowBlur?: number;
		};
	};
	splitLine?: {
		show: boolean;
	};
	axisLabel?: {
		formatter: (value: number | string) => string | string;
	};
};

export type YValues = {
	name?: string;
	type: string;
	yAxisIndex?: number;
	isMarkArea?: boolean; //Является ли серия интервалом
	isMarkLine?: boolean; //является ли серия линией (TODO: Объединить это потом все в тип серии)
	data?: number[] | [string, number][];
	yAsixIndex?: number;
	colorBy?: string;
	markLine?: {
		label: {
			show: boolean;
			formatter: string;
		};
		silent: boolean;
		symbol: string;
		data: MarkData[];
		lineStyle: {
			color?: string;
			type?: string;
			opacity?: number;
		};
	};
	smooth?: boolean;
	symbol?: string;
	zlevel?: number;
	z?: number;
	lineStyle?: {
		color?: string;
		type?: string;
	};
	itemStyle?: {
		color?: string;
	};
	markArea?: {
		itemStyle: {
			color?: string;
			opacity?: number;
		};
		data: [MarkData, MarkData][];
	};
	showSymbol?: boolean;
	sampling?: string;
	large?: boolean;
	largeThreshold?: number;
};
export type MarkData = {
	id?: string;
	xAxis: string | number;
};
export type TextStyle = {
	fontFamily: string;
};
export type ToolTip = {
	trigger: string;
	textStyle: {
		fontWeight: string;
	};
	axisPointer: {
		type: string;
	};
	order?: string;
	formatter?: (params: TooltipParam[]) => string;
	valueFormatter?: (value: number) => string;
};

export type TooltipParam = {
	componentType: string;
	componentSubType: string;
	componentIndex: number;
	seriesType: string;
	seriesIndex: number;
	seriesId: string;
	seriesName: string;
	name: string;
	dataIndex: number;
	data: [string, number];
	value: [string, number];
	color: string;
	dimensionNames: string[];
	encode: Point;
	$vars: string[];
	axisDim: string;
	axisIndex: number;
	axisType: string;
	axisId: string;
	axisValue: string;
	axisValueLabel: string;
	marker: string;
};

export enum ColorTypeEnum {
	chart = "chart",
	tag = "tag",
}

export type IChartData = {
	type: string;
	tag: string;
	points: Point[];
	unit: string;
};

// Варианты страниц, на которой расположен график
export enum ChartType {
	analysis = "analysis",
	modeling = "modeling",
}

export enum ChartTypesEnum {
	mainModeling = "mainModeling",
	anomalyModeling = "anomalyModeling",
	mainAnalysis = "mainAnalysis",
	anomalyAnalysis = "anomalyAnalysis",
}

export type IChartTooltipData = {
	header?: string;
	columns: string[];
	rows: string[][];
};
export type IBrushSettings = {
	xAxisIndex: string;
	brushLink: string;
	outOfBrush: {
		colorAlpha: number;
	};
	brushStyle: {
		borderWidth: number;
		color: string;
		borderColor: string;
	};
	brushType: string;
	seriesIndex: string;
	throttleType: string;
	throttleDelay: number;
	toolbox: string[];
};

export enum brushEventType {
	start = "start",
	continue = "continue",
	end = "end",
}

/* Тип для линия  */
export type MarkLine = {
	position: string; //размещение линии
	name: string; // названии линии
	typeLine: string; // тип линии
	colorLine: string; // цвет линии
};

export type ChartEvent = {
	type: string;
	start?: number;
	end?: number;
	batch?: any[];
	chartName?: string;
	timeSlice?: string;
	instance?: any;
	areas?: any;
	brush: IBrushSettings | null;
};

/* Перечисление возможных вариантов осей */
export enum YAxisType {
	Units, //базовое поведение, когда для каждой единицы измерения своя ось
	Multi, //для каждого временного ряда свое мин и макс значение
	Single, // все на одну ось помещается
}

//Видимость сетки
export type TGridVisibleSettings = {
	xGrid: boolean;
	yGrid: boolean;
};

export type TGapBoundaries = {
	min: number;
	max: number;
};
