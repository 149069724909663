import { IconComponent } from "@consta/icons/Icon";
import {
	ThemePreset,
	presetGpnDefault,
	presetGpnDark,
} from "@consta/uikit/Theme";
import { IconMoon } from "@consta/icons/IconMoon";
import { IconSun } from "@consta/icons/IconSun";
export type ThemeType = {
	label: string;
	theme: ThemePreset;
	icon: IconComponent;
};

export const AVIABLE_THEME_CONFIGS: ThemeType[] = [
	{
		theme: presetGpnDark,
		label: "Темная тема",
		icon: IconMoon,
	},
	{
		theme: presetGpnDefault,
		label: "Светлая тема",
		icon: IconSun,
	},
];
