import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { forIn } from "lodash";
import { environment } from "../../../environment";
import { urlConcat } from "../../../shared/utils/url-concat";
import { ConfigsList, uiConfigNames } from "../../../types/common/ui-data";
import { requestType } from "../../../types/system/i-request";
import { parsingDataFromJSON } from "../slices/ui-config-slice";
import { RootState } from "../store";
import {
	UIDataApi,
	UIDataApiGetPayload,
	UIDataApiUpdatePayload,
} from "../../../types/api/i-api-uidata";

export const uiDataConfigApi = createApi({
	reducerPath: "ui-data",
	baseQuery: fetchBaseQuery({
		baseUrl: urlConcat(environment.urlGateway.trim(), "/api/"),
		credentials: "include",
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).authManager.userToken;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),

	endpoints: (builder) => ({
		getAllUIDataByIdObject: builder.query<ConfigsList, string>({
			async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
				const postfix = !arg ? "" : `@${arg}`;
				const promises: Promise<{ data: UIDataApi }>[] = [];
				const configsMap: string[] = [];
				forIn(uiConfigNames, (value) => {
					const newPromise = fetchWithBQ(
						`/UIUserDatas/${value}${postfix}`
					) as Promise<{ data: UIDataApi }>;
					promises.push(newPromise);
					configsMap.push(value);
				});
				const response = await Promise.all(promises);

				const res: ConfigsList = {};

				const isError =
					response.filter((config) => !config?.data).length === response.length;

				if (isError) {
					return {
						error: {
							status: 400,
							statusText: "Bad Request",
							data: res,
						},
					};
				}

				for (let index = 0; index < response.length; index++) {
					const curResp = response[index];
					if (curResp.data) {
						const curType = configsMap[index] as uiConfigNames;
						res[curType] = parsingDataFromJSON(curResp.data.data);
					}
				}

				return { data: res };
			},
		}),
		getUIDataConfigByName: builder.query<any, UIDataApiGetPayload>({
			query: ({ userName, configName }) =>
				`UIUserDatas/${userName || "s"}@${configName}`,
			transformResponse: (response: UIDataApi) => JSON.parse(response.data),
		}),
		updateUIDataConfigByName: builder.query<UIDataApi, UIDataApiUpdatePayload>({
			query: ({ userName, configName, data }) => ({
				url: `UIUserDatas/${userName || "s"}@${configName}`,
				method: requestType.POST,
				body: data,
			}),
		}),
	}),
});

export const {
	useLazyGetAllUIDataByIdObjectQuery,
	useLazyGetUIDataConfigByNameQuery,
	useLazyUpdateUIDataConfigByNameQuery,
} = uiDataConfigApi;
