import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { roleType } from "../../../types/api/i-auth";

export type IAuthManager = {
	authenticated: boolean;
	currentRole: string;
	userName: string;
	userToken: string;
	sessionId: string;
	userLoggin: string;
	permissions: string[];
};
const INITAL_AUTH_STATE: IAuthManager = {
	authenticated: false,
	currentRole: roleType.unauthorized,
	userName: roleType.unauthorized,
	userToken: roleType.unauthorized,
	userLoggin: roleType.unauthorized,
	sessionId: roleType.unauthorized,
	permissions: [],
};

const authManagerSlice = createSlice({
	name: "authManager",
	initialState: INITAL_AUTH_STATE,
	reducers: {
		initAuth: (state, action: PayloadAction<IAuthManager>) => {
			state.authenticated = true;
			const newRole = action.payload.currentRole;
			const newName = action.payload.userName;
			const newLoggin = action.payload.userLoggin;
			const newToken = action.payload.userToken;
			const newSessionId = newName;
			if (newLoggin) {
				state.userLoggin = newLoggin;
			}
			if (newName) {
				state.userName = newName;
			}
			if (newRole) {
				state.currentRole = newRole;
			}
			if (newToken) {
				state.userToken = newToken;
			}
			if (newSessionId) {
				state.sessionId = newSessionId;
			}
		},
		setToken: (state, action: PayloadAction<string>) => {
			state.userToken = action.payload;
		},
		setUserRole: (state, action: PayloadAction<string>) => {
			state.currentRole = action.payload;
		},
		setPermissions: (state, action: PayloadAction<string[]>) => {
			state.permissions = action.payload;
		},
	},
});
export const { initAuth, setToken, setUserRole, setPermissions } =
	authManagerSlice.actions;
export default authManagerSlice;
