//Информация о пользователе и его доступах
export type IAccessList = {
	group: string;
	name: string;
	permissions: string[];
	preferred_username: string;
	premissions: string[];
	roles: string;
};

export enum roleType {
	unauthorized = "unauthorized",
}

export type IUserCredentials = {
	userName: string;
	userRole: string;
};
