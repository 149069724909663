import { Text } from "@consta/uikit/Text";
import React, { useEffect, useState } from "react";

import { Button } from "@consta/uikit/Button";
import { Layout } from "@consta/uikit/Layout";
import { IconClose } from "@consta/icons/IconClose";

import { Select } from "@consta/uikit/Select";
import { TextField } from "@consta/uikit/TextField";
import { DragNDropField } from "@consta/uikit/DragNDropField";
import { Attachment } from "@consta/uikit/Attachment";
import { PropStatus } from "@consta/uikit/__internal__/src/components/SelectComponents/types";
import { IconTrash } from "@consta/icons/IconTrash";
import { useSetFeedbackMutation } from "../../services/API/api-feedback-file";

import { environment } from "../../../environment";
import styles from "./FeedbackPart.module.scss";
import { REASONS_LIST } from "./reasons-list";
import { IItem } from "../../../types/system/i-item";
import { IUserCredentials } from "../../../types/api/i-auth";

const FeedbackPart: React.FC<{
	setFeedbackOpen?: (_isOpen: boolean) => void;
	userCredentials: IUserCredentials;
	isFeedbackOpen: boolean;
}> = ({ setFeedbackOpen, isFeedbackOpen, userCredentials }) => {
	const [open, setOpen] = useState<boolean>(isFeedbackOpen);
	const [reason, setReason] = useState<IItem | null>();
	const [files, setFiles] = React.useState<File[]>([]);
	const [valid, setValid] = useState<PropStatus>();
	const [description, setDescription] = useState<string | null>(null);
	const [setFeedback] = useSetFeedbackMutation();
	const handleChange = ({ value }: { value: string | null }) =>
		setDescription(value);

	function setIsSidebarOpen(isOpen: boolean) {
		if (setFeedbackOpen) {
			setFeedbackOpen(isOpen);
		}
	}

	useEffect(() => {
		setIsSidebarOpen(open);
	}, [open]);

	const handleDropFiles = (newFiles: File[]) => {
		const uniqueNewFiles = newFiles.filter(
			(newFile) => !files.some((file) => file.name === newFile.name)
		);
		setFiles((prevFiles) => [...prevFiles, ...uniqueNewFiles]);
	};
	function sendFeedback() {
		if (description && reason) {
			const formData: FormData = new FormData();
			if (files.length > 0) {
				for (const file of files) {
					if (file instanceof Blob) {
						formData.append("Files", file, file.name);
					}
				}
			}
			formData.append(
				"Content",
				description + `\n[Версия интерфейса: ${environment.curVersion}]`
			);
			formData.append("Status", reason.label);
			setFeedback(formData);
			setOpen(false);
			setValid(undefined);
		} else {
			setValid("alert");
		}
	}

	function genPlaceholder() {
		if (files.length == 0) {
			return (
				<Layout direction="column">
					<Text size="s" view="ghost" align="center">
						Перетащите файлы сюда или загрузите по кнопке
					</Text>
					<Text size="s" view="ghost" align="center">
						Подходят файлы .doc, .docx, .txt, .xls, .xlsx, image до 20 Мб
					</Text>
				</Layout>
			);
		} else {
			return <div />;
		}
	}

	function getFileInfo(file: File): { name: string; extension: string } {
		const extension = file.name.split(".");
		if (extension && extension.at(-1)) {
			return {
				name: extension[0],
				extension: extension[1],
			};
		} else {
			return {
				name: "",
				extension: "",
			};
		}
	}

	function genFileView(file: File): React.ReactNode {
		const fileInfo = getFileInfo(file);
		return (
			<Attachment
				fileName={fileInfo.name}
				fileExtension={fileInfo.extension}
				buttonIcon={IconTrash}
				buttonTitle="Удалить"
				onButtonClick={(e) => {
					e.stopPropagation();
					setFiles(files.filter((curFile) => curFile.name !== file.name));
				}}
			/>
		);
	}

	return (
		<Layout className={styles.body} direction="column">
			<Layout className={styles.header}>
				<Text size="l" view="primary">
					Обратная связь
				</Text>
				<Button
					data-analytics-id="feedback-close-button"
					size="s"
					label="Закрыть"
					iconLeft={IconClose}
					onlyIcon
					view="clear"
					onClick={() => setOpen(false)}
				/>
			</Layout>
			<Layout className={styles.mainBlock} direction="column">
				<Layout className={styles.field} direction="column">
					<Text size="m" view="secondary">
						Отправитель:
					</Text>
					<Text size="m" view="primary">
						{`${userCredentials.userName}, ${userCredentials.userRole}`}
					</Text>
				</Layout>
				<Layout className={styles.field} direction="column">
					<Text size="m" view="secondary">
						Категория:
					</Text>
					<Select
						data-analytics-id="feedback-category-selector"
						items={REASONS_LIST}
						value={reason}
						status={valid}
						placeholder="Выберите категорию"
						className={styles.form}
						onChange={({ value }): void => setReason(value)}
					/>
				</Layout>
				<Layout className={styles.field} direction="column">
					<Text size="m" view="secondary">
						Описание проблемы:
					</Text>
					<TextField
						data-analytics-id="feedback-description-textarea"
						className={styles.form}
						onChange={handleChange}
						size="s"
						status={valid}
						value={description}
						type="textarea"
						rows={5}
						placeholder="Опишите проблему"
					/>
				</Layout>
				<Layout className={styles.field} direction="column">
					<Text size="m" view="secondary">
						Прикрепите файл:
					</Text>
					<DragNDropField
						data-analytics-id="feedback-file-draganddrop"
						className={styles.form}
						multiple
						accept={[".doc", ".docx", ".txt", ".xls", ".xlsx", "image/*", ".json"]}
						maxSize={20 * 1024 * 1024}
						onDropFiles={handleDropFiles}>
						{({ openFileDialog }) => (
							<>
								{genPlaceholder()}
								{Boolean(files.length) && (
									<Layout direction="column" className={styles.filesContainer}>
										{files.map((item) => genFileView(item))}
									</Layout>
								)}
								<br />
								<Button onClick={openFileDialog} size="s" label="Выбрать файл" />
							</>
						)}
					</DragNDropField>
				</Layout>
			</Layout>
			<Layout className={styles.actionsBlock}>
				<Button
					data-analytics-id="feedback-send-button"
					size="m"
					label="Отправить"
					view="primary"
					onClick={() => sendFeedback()}
				/>
			</Layout>
		</Layout>
	);
};

export default FeedbackPart;
