import { createSlice } from "@reduxjs/toolkit";
import { uiDataConfigApi } from "../API/api-ui-data-redux";
import { ConfigsList } from "../../../types/common/ui-data";

/* Доработать конфиг */
export const parsingDataFromJSON = (strData: string) => {
	try {
		return JSON.parse(strData);
	} catch (err) {
		return strData;
	}
};

/* Глобальное состояние */
export type UIConfigState = {
	selectedObject: string;
	configsList: ConfigsList;
};
const initialState: UIConfigState = {
	selectedObject: "",
	configsList: {},
};
const uiConfigSlice = createSlice({
	name: "uiConfigSlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			uiDataConfigApi.endpoints.getAllUIDataByIdObject.matchFulfilled,
			(state, action) => ({
				...state,
				selectedObject: action.meta.arg.originalArgs,
				configsList: action.payload,
			})
		);
	},
});
export default uiConfigSlice;
