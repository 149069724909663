export function parseFullUserName(name: string): string {
	const curName = name.split(".")[0];
	const initials = name.split(".")[1]?.toUpperCase();
	if (!initials) return name;
	const splitted = curName.split("");
	const first = splitted[0].toUpperCase();
	const rest = [...splitted];
	rest.splice(0, 1);
	const result = [first, ...rest].join("") + "." + initials;
	return result;
}
