import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";

import { Provider } from "react-redux";

import { AUTH_CONFIG } from "./auth-config";
import { AuthProvider } from "react-oidc-context";
import { store } from "./core/services/store";
import { environment } from "./environment";

async function enableMocking() {
	console.log("environment", environment);
	if (!environment.isMocked) {
		return;
	}
	try {
		const { browserWorker } = await import("./mocs/browser");
		browserWorker.start();
		// `worker.start()` returns a Promise that resolves
		// once the Service Worker is up and ready to intercept requests.
		console.log("worker.listHandlers()", browserWorker);

		return browserWorker.start();
	} catch (err) {
		console.log("mockingError", err);
	}
}

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

enableMocking().then(() => {
	root.render(
		<AuthProvider {...AUTH_CONFIG}>
			<React.StrictMode>
				<Provider store={store}>
					<App />
				</Provider>
			</React.StrictMode>
		</AuthProvider>
	);
});
