import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import FileSaver from "file-saver";
import { environment } from "../../../environment";
import { getFileName } from "../../../shared/utils/file-operations";
import { urlConcat } from "../../../shared/utils/url-concat";
import { IEconomicReportPayload } from "../../../types/system/i-economic";
import { RootState } from "../store";

export const reportServiceApi = createApi({
	reducerPath: "report-service",
	baseQuery: fetchBaseQuery({
		baseUrl: urlConcat(environment.urlReportService.trim(), "/api/"),
		credentials: "include",
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).authManager.userToken;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		// получение всех значений воздействий переменных
		getEffectReport: builder.query<Blob, IEconomicReportPayload>({
			query: (data: IEconomicReportPayload) => ({
				url: `Reports/effect`,
				params: {
					effect: data.effect,
					from: data.from,
					to: data.to,
				},
				responseHandler: async (response) => {
					const file = await response.blob();
					const name = getFileName(response.headers);
					FileSaver.saveAs(file, name);
				},
			}),
		}),
	}),
});

export const { useLazyGetEffectReportQuery } = reportServiceApi;
